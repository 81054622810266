import { Destination } from "@/types/audience";

export const MAX_COMPANIES = 3000000;

export const MAX_PEOPLE = 3000000;

export const DESTINATIONS_MIN: { [key: string]: number } = {
    [Destination.GOOGLE]: 1000,
    [Destination.META]: 100,
    [Destination.LINKEDIN]: 300,
};
