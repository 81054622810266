import cn from "classnames";
import ArrowRightIcon from "@/assets/icons/arrow-right-sm.svg?react";
import CloseIcon from "@/assets/icons/close.svg?react";

import { Button } from "@/components/atoms/Button/Button";
import { Tooltip } from "@/components/atoms/Tooltip/Tooltip";

const RowAction: React.FC<{
    onClick: () => void;
    isOther: boolean;
    allowExpand: boolean;
    isExcluding: boolean;
    isExpanding?: boolean;
    isSelectedForExclusion: boolean;
    isExcludingItem: boolean;
}> = ({ onClick, isOther, allowExpand, isExcluding, isExpanding, isSelectedForExclusion, isExcludingItem }) => {

    const excludeButton = (
        <Tooltip
            content={isExcludingItem ? "Cancel" : "Exclude"}
            className="max-w-56 text-xs"
            side={isOther ? "bottom" : "right"}
            button={{
                role: "menuitem"
            }}
        >
            <Button
                {...(isExcludingItem ? { disabled: true } : {})}
                onClick={onClick}
                variant="basic"
                size="custom"
                role="button"
                className={cn(`!flex items-center justify-center h-[16px]w-[12px] group-hover:visible !border-transparent`, {
                    'visible': isSelectedForExclusion,
                    'invisible': !isSelectedForExclusion,
                })}>

                <CloseIcon className={cn('', {
                    "text-ui-300": !isSelectedForExclusion && !isExcludingItem,
                    "text-[#B32424]": isSelectedForExclusion && !isExcludingItem,
                    "text-ui-200": isSelectedForExclusion && isExcludingItem
                })} />
            </Button>
        </Tooltip >);

    if (isOther && allowExpand && !isExpanding) {
        return (
            <Button
                variant="basic"
                size="custom"
                disabled={isExcluding}
                className={cn("!flex items-center justify-center h-[16px] w-[12px] !bg-transparent !border-transparent", {
                    "text-ui-300 group-hover:text-blue-800": !isExcluding,
                })}
            >
                <ArrowRightIcon />
            </Button>
        );
    } else if (isOther) {
        return <div className="py-0 px-1 h-[16px] w-[12px]"></div>;
    } else {
        return excludeButton;
    }
};

export default RowAction;
