import React from "react";
import { OnboardingProvider, useOnboarding } from "./OnboardingContext";

import { CRMConnect } from "@/components/organisms/onboarding/CRMConnect";
import { AddPlatformsConnect } from "@/components/organisms/onboarding/AddPlatformsConnect";
import { AudienceTypes } from "@/components/organisms/onboarding/AudienceTypes";

import { OnboardingLastStep } from "@/components/organisms/onboarding/OnboardingLastStep";

const withOnboardingProvider = (Component: React.ComponentType) => {
    return function WrapperComponent(props: any) {
        return (
            <OnboardingProvider>
                <Component {...props} />
            </OnboardingProvider>
        );
    };
};

export const Onboarding: React.FC = () => {
    const { currentStep, onboarding } = useOnboarding();
    if (!onboarding) return null;

    switch (currentStep) {
        case 1:
            return <AudienceTypes />;
        case 2:
            return <CRMConnect />;
        case 3:
            return <AddPlatformsConnect />;
        case 4:
            return <OnboardingLastStep />;
        default:
            return null;
    }
};

export const OnboardingPage = withOnboardingProvider(Onboarding);
