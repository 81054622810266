/* eslint-disable react-hooks/exhaustive-deps */
import { CriteriaAssets, GetAudienceResponse, GetCriteriaAssetsRequest, GetCriteriaAssetsResponse } from "@/types/api";
import { useEffect, useMemo, useRef, useState } from "react";
import { useGetCriteriaAssetsMutation } from "@/api/audiences.ts";
import { SourceCriteria } from "@primer/filters/types";
import { AudienceShapeHeuristicsSchema } from "@/types/audience";
import { useParams } from "react-router";
import { differenceInHours } from "date-fns";
import { v4 as uuidv4 } from "uuid";

export const useCriteriaAssetsManager = ({
    audienceId,
    audience,
}: {
    audienceId?: string;
    audience?: GetAudienceResponse;
}) => {
    const [criteriaAssets, setCriteriaAssets] = useState<AudienceShapeHeuristicsSchema | undefined>();
    const requestedAssets = useRef<
        {
            id: string;
            assets: CriteriaAssets[];
        }[]
    >([]);
    const requestCounter = useRef("");
    const [isExpanding, setIsExpanding] = useState(false);
    const { id } = useParams();

    const [getCriteriaAssetsMutation, { isLoading }] = useGetCriteriaAssetsMutation();
    const getCriteriaAssets = async (req: GetCriteriaAssetsRequest): Promise<GetCriteriaAssetsResponse> =>
        getCriteriaAssetsMutation(req).unwrap();

    useEffect(() => {
        if (!criteriaAssets && ((!audienceId && !id) || audience)) {
            if (
                !audience?.shape?.heuristics?.created_at ||
                audience?.shape?.heuristics?.heuristics?.top?.some(t => t.total && t.total >= 0) ||
                differenceInHours(new Date(), audience?.shape?.heuristics?.created_at) > 11
            ) {
                updateCriteriaAssets();
            } else {
                setCriteriaAssets(audience?.shape?.heuristics);
            }
        }
    }, [criteriaAssets, audienceId, audience]);

    const isLoadingSize = useMemo(() => {
        return requestedAssets.current.some(({ assets }) => assets.includes(CriteriaAssets.SIZE)) && isLoading;
    }, [isLoading, requestedAssets.current]);

    const isLoadingSummary = useMemo(() => {
        return requestedAssets.current.some(({ assets }) => assets.includes(CriteriaAssets.SUMMARY)) && isLoading;
    }, [isLoading, requestedAssets.current]);

    const isLoadingPreview = useMemo(() => {
        return requestedAssets.current.some(({ assets }) => assets.includes(CriteriaAssets.PREVIEW)) && isLoading;
    }, [isLoading, requestedAssets.current]);

    const updateCriteriaAssets = async (body: Partial<GetCriteriaAssetsRequest> = {}) => {
        const assets = body?.assets ?? [CriteriaAssets.SIZE, CriteriaAssets.SUMMARY, CriteriaAssets.PREVIEW];
        const currentRequest = uuidv4();
        requestCounter.current = currentRequest;
        requestedAssets.current = [
            ...requestedAssets.current,
            {
                id: currentRequest,
                assets,
            },
        ];
        const criteriaAssets = await getCriteriaAssets({
            assets: assets,
            source_criteria: !audience?.shape?.source_criteria
                ? undefined
                : (audience?.shape?.source_criteria as SourceCriteria),
            audience_id: audience?.id,
            shape_id: audience?.shape.id,
            ...body,
        });
        if (currentRequest === requestCounter.current) {
            setCriteriaAssets(criteriaAssets);
            setIsExpanding(false);
        }
        requestedAssets.current = requestedAssets.current.filter(p => p.id !== currentRequest);
    };

    const expandSummary = () => {
        setIsExpanding(true);
        updateCriteriaAssets({
            summary_limit: 100,
            assets: [CriteriaAssets.SUMMARY],
        });
    };

    return {
        isLoadingSize,
        isLoadingSummary,
        isLoadingPreview,
        isExpandingSummary: isExpanding,
        criteriaAssets,
        setCriteriaAssets,
        updateCriteriaAssets,
        expandSummary,
    };
};
