import { TrialBanner } from "./TrialBanner";
import { useStatisticsQuery } from "@/api/audiences";
import { useSubscriptionQuery } from "@/api/users";
import { SubscriptionStatus } from "@/types/subscription";
import { SubscriptionExpiredBanner } from "./SubscriptionExpiredBanner";
import { useEffect } from "react";
import { useToast } from "@/components/atoms/Toast/useToast";

export const SubscriptionBanner = () => {
    const { toast } = useToast();
    const { data: subscription, isLoading, isError } = useSubscriptionQuery(undefined);
    const { data: statistics } = useStatisticsQuery(void true, { refetchOnMountOrArgChange: true });

    useEffect(() => {
        if (!isError) return;

        toast({
            variant: "destructive",
            description:
                "Error while getting user subscription. All audiences have stopped updating. Please contact support",
        });
    }, [toast, isError]);

    if (isLoading || isError) return null;

    const render = () => {
        switch (subscription?.status) {
            case SubscriptionStatus.ACTIVE:
                return null;
            case SubscriptionStatus.TRIALING:
                return <TrialBanner subscription={subscription} audiencesLimit={statistics?.audiencesMaxLimit} />;

            default:
                return <SubscriptionExpiredBanner status={subscription?.status || SubscriptionStatus.INCOMPLETE} />;
        }
    };
    return <>{render()}</>;
};
