/* eslint-disable react-hooks/exhaustive-deps */
import postHog from "posthog-js";
import { useNavigation } from "@/context/NavigationContext";
import { findIndex } from "lodash";
import * as Tabs from "@radix-ui/react-tabs";
import * as Poppover from "@radix-ui/react-popover";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import Selector from "./Selector";
import FilterFieldSearch from "./Search";
import FilterFieldTabHeader from "./TabHeader";
import { useGetDatasetsQuery } from "@/api/csv";
import { filtersFields } from "@primer/filters/configs";
import { FeatureFlagsEnum, useFeatureFlag } from "@/hooks";
import { FirstPartyTabContent } from "./FirstPartyTabContent";
import { CSVImportTabContent } from "../csv/ImportCsvTabContent";
import { useGetConnectionsHash } from "@/hooks/useGetConnectionsHash";
import { useGetFilterCategories } from "@/hooks/useGetFilterCategories";
import FilterFieldTabContent, { CategoriesFilterFields } from "./TabContent";
import { useGetFirstPartyFiltersQuery, useGetRecentlyUsedFiltersQuery } from "@/api/filters";
import { FilterConfig, FilterEntityTypes, FilterCategory, CategoryConfig, SourceCriteria } from "@primer/filters/types";

export interface FilterFieldProps {
    open: boolean;
    disabled: boolean;
    entityType: FilterEntityTypes;
    setOpen: (open: boolean) => void;
    selectedFilterField?: FilterConfig;
    onSelectAllObjects: (value: FilterConfig) => void;
    setSelectedFilterField: (value: FilterConfig) => void;
    sourceCriteria?: SourceCriteria;
}

const FilterFieldRoot = ({
    open,
    setOpen,
    disabled,
    entityType,
    onSelectAllObjects,
    selectedFilterField,
    setSelectedFilterField,
    sourceCriteria,
}: FilterFieldProps) => {
    const inputRef = useRef<HTMLInputElement>(null);
    const [searchText, setSearchText] = useState<string>("");
    const [currentTab, setCurrentTab] = useState<FilterCategory>(FilterCategory.RECENTLY_USED);

    const csvImportEnabled = useFeatureFlag(FeatureFlagsEnum.CSV_IMPORT);

    const [connectionsHash] = useGetConnectionsHash();

    const { audienceId } = useNavigation();

    const onTabChange = (tabId: string) => setTimeout(() => setCurrentTab(tabId as FilterCategory), 100);

    useEffect(() => inputRef?.current?.focus(), [currentTab]);
    useEffect(() => setSearchText(""), [open]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => setOpen(false), [selectedFilterField]);

    const handleSetSelectedFilterField = (value: FilterConfig) => {
        // When a new filter is added, send event to posthog
        postHog.capture("Filter added", {
            buttonClass: "audience-filter-type",
            filterTab: currentTab,
            filterName: value.identifier,
            audienceId: audienceId ?? "new-audience",
        });

        setSelectedFilterField(value);
    };

    const { data: recentlyUsedFilters = [] } = useGetRecentlyUsedFiltersQuery(undefined);
    const { data: firstPartyFilters, isLoading } = useGetFirstPartyFiltersQuery(
        { instanceId: connectionsHash.salesforce?.provider_account_id ?? "" },
        {
            skip: !connectionsHash.salesforce?.provider_account_id,
        },
    );

    const { data: csvFiles } = useGetDatasetsQuery(
        {},
        {
            skip: !csvImportEnabled,
        },
    );

    const filterCategories = useGetFilterCategories();

    const getAvailableFields = () => {
        const usedFields = sourceCriteria?.group?.filters ?? [];
        const availableFilters = filtersFields.filter(field => {
            const usedField = usedFields.find(
                used => used.field === field.identifier && used.entity_type === field.entityType,
            );

            if (!usedField) return true;

            const usedOperators = usedFields
                .filter(used => used.field === field.identifier && used.entity_type === field.entityType)
                .map(used => used.operator);

            const isOperatorAvailable = field.allowedOperators.some(operator => !usedOperators.includes(operator));

            return isOperatorAvailable;
        });
        return availableFilters;
    };

    const filterBySearchText = (fieldName: string) =>
        !searchText || fieldName.toLowerCase().includes(searchText.toLowerCase());

    const getCategoryFields = useCallback(
        (category: CategoryConfig): FilterConfig[] => {
            const availableFields = getAvailableFields();

            if (category.id === FilterCategory.RECENTLY_USED) {
                return recentlyUsedFilters
                    .map(recent =>
                        availableFields.find(
                            field =>
                                recent.field === field.identifier &&
                                recent.entity_type === field.entityType &&
                                filterBySearchText(field.name),
                        ),
                    )
                    .filter(Boolean) as FilterConfig[];
            } else {
                return availableFields.filter(
                    field =>
                        (entityType === FilterEntityTypes.PERSON || field.entityType === entityType) &&
                        field.categories.includes(category.id) &&
                        filterBySearchText(field.name),
                );
            }
        },
        [entityType, recentlyUsedFilters, searchText, sourceCriteria],
    );

    const categories: CategoriesFilterFields[] = useMemo(() => {
        return filterCategories.map(category => ({
            ...category,
            fields: getCategoryFields(category),
        }));
    }, [getCategoryFields, firstPartyFilters]);
    useEffect(() => moveToTabWithSearchField(), [categories]);

    const moveToTabWithSearchField = () => {
        if (!searchText) return;

        let firstValidCategoryIndex: number | undefined = undefined;

        categories.forEach((category, index) => {
            if (firstValidCategoryIndex !== undefined) return;
            if (category.fields.length) firstValidCategoryIndex = index;
        });

        const currentTabIndex = findIndex(categories, f => f.id === currentTab);
        if (currentTabIndex === firstValidCategoryIndex) return;

        const hasMatchingFirstParty = firstPartyFilters?.some(f => filterBySearchText(f.id));
        const hasMatchingCsv = csvFiles?.some(f => filterBySearchText(f.name));
        
        // If already is on csv, proritize csv
        // If already is on salesforce, proritize salesforce
        // If it's not either, prioritize primer
        if (hasMatchingCsv && currentTabIndex === 3) {
            onTabChange(FilterCategory.CSV_FILES.toString());
        } if (hasMatchingFirstParty && currentTabIndex === 2) {
            onTabChange(FilterCategory.SALESFORCE.toString());
        } else if (firstValidCategoryIndex !== undefined) {
            onTabChange(FilterCategory.PRIMER.toString());
        } else if (hasMatchingCsv) {
            onTabChange(FilterCategory.CSV_FILES.toString());
        } else if (hasMatchingFirstParty) {
            onTabChange(FilterCategory.SALESFORCE.toString());
        }
    };

    const tabContentComponent = (currentTab: FilterCategory) => {
        switch (currentTab) {
            case FilterCategory.SALESFORCE:
                return (
                    <FirstPartyTabContent
                        isLoading={isLoading}
                        searchText={searchText}
                        setSearchText={setSearchText}
                        firstPartyFilters={firstPartyFilters}
                        onSelectAllObjects={onSelectAllObjects}
                        setSelectedFilterField={setSelectedFilterField}
                    />
                );

            case FilterCategory.CSV_FILES:
                return (
                    <CSVImportTabContent
                        files={csvFiles || []}
                        searchText={searchText}
                        setSelectedFilterField={setSelectedFilterField}
                        selectedFilterField={selectedFilterField}
                        usedFields={sourceCriteria?.group?.filters ?? []}
                    />
                );

            default:
                return (
                    <FilterFieldTabContent
                        categories={categories}
                        setSelectedFilterField={handleSetSelectedFilterField}
                    />
                );
        }
    };

    return (
        <Poppover.Root open={open} onOpenChange={setOpen}>
            <Poppover.Trigger asChild>
                {!disabled && <Selector selectedFilterField={selectedFilterField} />}
            </Poppover.Trigger>

            <Poppover.Portal>
                <Poppover.Content
                    className="w-[600px] bg-ui-50 rounded-[15px] overflow-hidden data-[side=top]:shadow-dropdown-t data-[side=bottom]:shadow-dropdown-b data-[side=top]:animate-slideDownAndFade data-[side=bottom]:animate-slideUpAndFade z-20"
                    align="start"
                    collisionPadding={5}
                    sideOffset={-40}
                    alignOffset={-4}
                >
                    <FilterFieldSearch
                        searchText={searchText}
                        setSearchText={setSearchText}
                        inputRef={inputRef}
                        setOpen={setOpen}
                    />
                    <Tabs.Root
                        value={currentTab.toString()}
                        activationMode="manual"
                        onValueChange={onTabChange}
                        className="rounded-md"
                    >
                        <div className="px-4">
                            <FilterFieldTabHeader />
                        </div>

                        <div className="flex flex-col bg-white">{tabContentComponent(currentTab)}</div>
                    </Tabs.Root>
                </Poppover.Content>
            </Poppover.Portal>
        </Poppover.Root>
    );
};

export default FilterFieldRoot;
