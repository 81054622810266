import { SignIn } from "@clerk/clerk-react";

import { SplitPaneLayout } from "@/components/layout";
import SignupGraphic from "@/assets/icons/signup-graphic.svg";

const Login = () => {


    return (
        <SplitPaneLayout imgUrl={SignupGraphic}>
            <SignIn />
        </SplitPaneLayout>
    );
};

export default Login;
