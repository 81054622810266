import postHog from "posthog-js";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useEffect, useMemo, useRef, useState } from "react";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query/react";

import SearchIcon from "@/assets/icons/search.svg?react";
import FilterIcon from "@/assets/icons/filter.svg?react";
import ArrowRightIcon from "@/assets/icons/arrow-right.svg?react";

import { api } from "@/api/index";
import { useGetAudiencesQuery, usePrefetch } from "@/api/audiences";
import { GetAudienceSortColumn, GetAudiencesRequest } from "@/types/api";

import { FeatureFlagsEnum, useFeatureFlag } from "@/hooks/useFeatureFlag";
import { usePaginationWithQueryParams, useSearch, useSort } from "@/hooks";
import { useAudienceResultsFilter } from "@/hooks/useAudienceResultsFilter";

import { EmptyState } from "./EmptyState";
import { Audience } from "@/types/audience";
import { AudienceTable } from "./AudienceTable";
import { Input } from "@/components/atoms/Input/Input";
import { Button } from "@/components/atoms/Button/Button.tsx";
import { FiltersPopoverContent } from "./FiltersPopoverContent";
import { useToast } from "@/components/atoms/Toast/useToast.tsx";
import { EmptySearchFilterResults } from "./EmptySearchFilterResults";
import Pagination from "@/components/molecules/Pagination/Pagination.tsx";
import { Popover, PopoverTrigger } from "@/components/atoms/Popover/Popover";
import { AudienceRunsModal } from "@/components/organisms/audiences/RunsModal";
import { AudiencesInfoBar } from "@/components/organisms/audiences/AudiencesInfoBar";
import { SubscriptionBanner } from "@/components/organisms/subscription/SubscriptionBanner";
import { IntegrationErrorMessage } from "@/components/organisms/integrations/IntegrationErrorMessage";
import { TooltipMessageWrapper } from "@/components/organisms/audiences/AudiencesInfoBar/TooltipMessageWrapper";
import { useGetConnectionsQuery } from "@/api/integrations";


const AudiencesDashboard = () => {
    const [isFiltersOpen, setIsFiltersOpen] = useState(false);
    const pagination = usePaginationWithQueryParams();
    const { updateTotal, from, limit, hasNext, hasPrevious } = pagination;

    const paymentPitchEnabled = useFeatureFlag(FeatureFlagsEnum.PAYMENT_PITCH);


    const { sortColumn, sortOrder, handleSort } = useSort(
        Object.values(GetAudienceSortColumn),
        GetAudienceSortColumn.UPDATED_AT,
    );

    const warningsContainerRef = useRef(null);
    const [warningsContainerHeight, setWarningsContainerHeight] = useState(0);
    const [viewRunsOpened, setViewRunsOpened] = useState(false);
    const [audienceRunsId, setAudienceRunsId] = useState<string>();
    const handleViewRuns = (audienceId: string) => {
        setViewRunsOpened(true);
        setAudienceRunsId(audienceId);
    };

    const { filtersValue, resetFilters, setFilters } = useAudienceResultsFilter();

    const filterCount =
        filtersValue?.destinations.length + filtersValue?.status.length + (filtersValue?.inFavorites ? 1 : 0);

    const [searchValue, searchTerm, setSearchValue] = useSearch("");

    const getAudienceRequestParams: GetAudiencesRequest = {
        offset: from,
        searchTerm,
        limit,
        sort: sortColumn,
        order: sortOrder,
        filters: filtersValue,
    };
    const dispatch = useDispatch();

    const {
        data,
        isLoading,
        isFetching,
        isSuccess,
        error: getAudiencesError,
    } = useGetAudiencesQuery(getAudienceRequestParams, {
        pollingInterval: 1000 * 60 * 2,
        skipPollingIfUnfocused: true,
        refetchOnReconnect: true,
        refetchOnMountOrArgChange: true,
        refetchOnFocus: true,
    });

    const selectedAudienceForRunsView = useMemo(() => {
        return data?.audiences.find(a => a.id === audienceRunsId);
    }, [audienceRunsId, data?.audiences]);

    const { data: connections } = useGetConnectionsQuery({ enabled: true });
    const failedConnections = useMemo(() => (connections || []).filter(({ state }) => state === "failed"), [connections]);

    // Update current rtk query cache manually for seamless UI experience
    const handleFavoriteCacheUpdate = (audienceId: Audience["id"], isFavorite: boolean) => {
        dispatch<any>(
            api.util.updateQueryData("getAudiences" as never, getAudienceRequestParams as never, audiences => {
                Object.assign(audiences, {
                    audiences: data?.audiences.map(a => {
                        const newAudience = { ...a };
                        if (newAudience.id === audienceId) {
                            newAudience.isFavorite = isFavorite;
                        }
                        return newAudience;
                    }),
                });
            }),
        );
    };

    // Prefetch the next and previous pages, if available
    const prefetchPage = usePrefetch("getAudiences");
    useEffect(() => {
        if (hasNext) {
            prefetchPage({
                ...getAudienceRequestParams,
                offset: from + limit,
            });
        }
        if (hasPrevious && from - limit >= 0) {
            prefetchPage({
                ...getAudienceRequestParams,
                offset: from - limit,
            });
        }
    }, [isSuccess]);

    if (data?.paginationDetails) updateTotal(data?.paginationDetails?.totalCount || 0, isLoading);

    const isEmpty =
        (!isLoading && !isFetching && isSuccess && !data?.audiences.length && !searchTerm && filterCount === 0) ||
        getAudiencesError;

    const isEmptySearchOrFilterResults = isSuccess && !data?.audiences.length && (searchTerm || filterCount > 0);

    const { toast } = useToast();

    // Scroll to top when page changes
    useEffect(() => {
        document.getElementById("main-element")?.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    }, [from]);

    useEffect(() => {
        if (!getAudiencesError) return;

        const fetchBaseQueryError = getAudiencesError as FetchBaseQueryError;

        toast({ description: fetchBaseQueryError.data as string, variant: "destructive" });
    }, [toast, getAudiencesError]);

    const resetSearchAndFilters = () => {
        setSearchValue("");
        resetFilters();
    };

    useEffect(() => {
        const element = warningsContainerRef.current;
        if (!element) return;

        const resizeObserver = new ResizeObserver(entries => {
            for (const entry of entries) {
                setWarningsContainerHeight(entry.target.clientHeight);
            }
        });

        resizeObserver.observe(element);

        return () => resizeObserver.disconnect();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [warningsContainerRef.current]);

    return (
        <div className="min-h-full flex flex-col">
            <header className="flex-none flex py-6 px-12 bg-white m-1.5 mb-4 rounded-2xl shadow-main sticky top-[6px] z-[2] items-center">
                <h1 className="flex-1 text-h1 text-dark-900">Audiences</h1>

                {paymentPitchEnabled && <AudiencesInfoBar />}

                <div className="flex space-x-3 items-center justify-center">
                    <Button
                        asChild
                        onClick={() => {
                            postHog.capture("Create audience button clicked", { buttonId: "create-audience-button" });
                        }}
                    >
                        <Link to="/audiences/new" className="flex">
                            Create Audience
                            <ArrowRightIcon className="text-white ml-1" />
                        </Link>
                    </Button>
                </div>
            </header>

            <div ref={warningsContainerRef}>
                {paymentPitchEnabled && <SubscriptionBanner />}
                {failedConnections.length > 0 && <IntegrationErrorMessage failedConnections={failedConnections} />}
            </div>
            {!isEmpty && (
                <div className="flex items-center justify-between mb-2 mx-1.5 md:mx-12">
                    <div className="flex items-center relative">
                        <SearchIcon className="absolute ml-2 z-[1]" />
                        <Input
                            className="w-[284px] pl-8"
                            placeholder="Search by name"
                            value={searchValue}
                            onChange={e => setSearchValue(e.target.value)}
                            title="Search input"
                            showClearIcon
                        />
                    </div>
                    <div>
                        <Popover open={isFiltersOpen} onOpenChange={setIsFiltersOpen}>
                            <PopoverTrigger>
                                <div className="flex items-center">
                                    {paymentPitchEnabled && (
                                        <TooltipMessageWrapper
                                            anchor="filter"
                                            content="Now you can see all your active audiences, i.e. that have running, synced, or incomplete statuses."
                                        />
                                    )}
                                    <Button variant="secondary">
                                        <FilterIcon className="mr-1 text-ui-700" />
                                        Filters
                                        {filterCount > 0 && (
                                            <div className="bg-blue-800 px-1.5 rounded text-white text-sm leading-5 ml-1">
                                                {filterCount}
                                            </div>
                                        )}
                                    </Button>
                                </div>
                            </PopoverTrigger>
                            <FiltersPopoverContent
                                setSelectedFilters={setFilters}
                                resetFilters={resetFilters}
                                selectedFilters={filtersValue}
                            />
                        </Popover>
                    </div>
                </div>
            )}
            <div className="flex-1 overflow-hidden">
                {isEmpty && <EmptyState />}

                {isEmptySearchOrFilterResults && (
                    <EmptySearchFilterResults resetSearchAndFilters={resetSearchAndFilters} />
                )}

                {!isEmpty && !isEmptySearchOrFilterResults && (
                    <AudienceTable
                        handleSort={handleSort}
                        currentSort={{
                            order: sortOrder,
                            column: sortColumn,
                        }}
                        data={data?.audiences ?? []}
                        isLoading={isLoading}
                        handleFavoriteCacheUpdate={handleFavoriteCacheUpdate}
                        handleViewRuns={handleViewRuns}
                        warningsContainerHeight={warningsContainerHeight}
                    />
                )}
            </div>

            {!isEmpty && !isEmptySearchOrFilterResults && (
                <Pagination {...pagination} className="sticky bottom-0 bg-dark-100 p-2" />
            )}
            {viewRunsOpened && (
                <AudienceRunsModal
                    audienceId={audienceRunsId!}
                    audienceName={selectedAudienceForRunsView?.name || ""}
                    onClose={() => setViewRunsOpened(false)}
                />
            )}
        </div>
    );
};

export { AudiencesDashboard };
