/* eslint-disable react-hooks/exhaustive-deps */
import React, { Dispatch, SetStateAction } from "react";
import { sortBy, indexOf } from "lodash";
import { AudienceShapeHeuristicsSchema, ExtraChartSchema, SummaryMetricColumn } from "@/types/audience";
import { createOthers } from "@/utils/heuristics";
import { parseFilterValueLabel } from "@/utils/string";

const sortOrder: string[] = [
    SummaryMetricColumn.JOB_TITLE,
    SummaryMetricColumn.SENIORITY,
    SummaryMetricColumn.COMPANY_DOMAIN,
    SummaryMetricColumn.COMPANY_LOCATION,
    SummaryMetricColumn.HEADCOUNT,
    SummaryMetricColumn.ANNUAL_REVENUE,
    SummaryMetricColumn.INDUSTRY,
];
const personSummaries: string[] = [
    SummaryMetricColumn.JOB_TITLE,
    SummaryMetricColumn.SENIORITY,
    SummaryMetricColumn.COMPANY_DOMAIN,
];

export interface SummaryStateResponse {
    sortOrder: string[];
    summaryValues: ExtraChartSchema[];
    allowExpand: boolean;
    setSummaryValues: Dispatch<SetStateAction<ExtraChartSchema[]>>;
}

interface Props {
    criteriaAssets: AudienceShapeHeuristicsSchema | undefined;
}
export function useSummary({ criteriaAssets }: Props): SummaryStateResponse {
    const [summaryValues, setSummaryValues] = React.useState<ExtraChartSchema[]>([]);

    const allowExpand = React.useMemo(
        () => !criteriaAssets?.heuristics?.limit || criteriaAssets.heuristics.limit < 100,
        [criteriaAssets],
    );

    React.useEffect(() => {
        const withPercentage: ExtraChartSchema[] =
            criteriaAssets?.heuristics?.top?.map(category => {
                const topSummaryTotal = category.total;
                const audienceEstimateTotal = personSummaries.includes(category?.name ?? "")
                    ? criteriaAssets?.people_count
                    : criteriaAssets?.companies_count;
                const total: number = topSummaryTotal ?? audienceEstimateTotal ?? 1;

                return {
                    name: category.name,
                    total,
                    values: category.values.map(item => ({
                        ...item,
                        label: parseFilterValueLabel(
                            String(item.label).toLowerCase(),
                            item.label,
                            category?.name?.toLowerCase(),
                        ),
                        percentage: (item.value * 100) / total,
                    })),
                };
            }) ?? [];

        const namesToBeRemoved = getNamesToBeRemoved(withPercentage);
        const withoutExtraLocations = withPercentage?.filter(
            n => !namesToBeRemoved.includes(n?.name as SummaryMetricColumn),
        );
        const withOthers = withoutExtraLocations.map(v => createOthers(v)).filter(v => !!v);

        const sorted = sortBy(withOthers, category => {
            const index = indexOf(sortOrder, category.name);
            return index === -1 ? Number.MAX_SAFE_INTEGER : index;
        });
        setSummaryValues(sorted);
    }, [criteriaAssets]);

    const getNamesToBeRemoved = (withPercentage: ExtraChartSchema[] = []): SummaryMetricColumn[] => {
        const options = [
            SummaryMetricColumn.COMPANY_LOCATION,
            SummaryMetricColumn.COMPANY_COUNTRY,
            SummaryMetricColumn.COMPANY_STATE,
            SummaryMetricColumn.COMPANY_CITY,
        ];
        const country = withPercentage?.find(w => w.name === SummaryMetricColumn.COMPANY_COUNTRY);
        const state = withPercentage?.find(w => w.name === SummaryMetricColumn.COMPANY_STATE);
        const hasCity = withPercentage?.some(w => w.name === SummaryMetricColumn.COMPANY_CITY);

        if (country && !country?.values?.some(v => v.percentage > 80))
            return options.filter(o => o !== SummaryMetricColumn.COMPANY_COUNTRY);
        if (state && !state?.values?.some(v => v.percentage > 80))
            return options.filter(o => o !== SummaryMetricColumn.COMPANY_STATE);
        if (hasCity) return options.filter(o => o !== SummaryMetricColumn.COMPANY_CITY);

        return options.filter(o => o !== SummaryMetricColumn.COMPANY_LOCATION);
    };

    return {
        sortOrder,
        summaryValues,
        allowExpand,
        setSummaryValues,
    };
}
