import React from "react";
import * as Popover from "@radix-ui/react-popover";
import { ChevronDownIcon } from "@radix-ui/react-icons";
import {
    AddressLocation,
    FilterConfig,
    OperatorConfig,
    SourceCriteriaFilterValue,
} from "@primer/filters/types";
import SelectedOptionViewer from "./SelectedOptionViewer";
import OptionItem from "./OptionItem";
import { useMultiSelect } from "@/hooks/useMultiSelect";
import CircularProgressBar from "@/components/atoms/CircularProgressBar/CircularProgressBar";
import { Input } from "@/components/atoms/Input/Input";
import { Button } from "@/components/atoms/Button/Button";
import { parseValue } from "@/utils/string";
import { MAX_FILTER_VALUES } from "../../../constants/filters";
import { getFilterMaxSupportedValuesForField } from "@primer/filters/util/normalize";

export interface Option {
    value: string | number | AddressLocation;
    label?: string;
    category?: string;
    checked: boolean;
}

export interface SelectedOption extends Omit<Option, "checked"> {
    invalid?: boolean;
    exceeded?: boolean;
}

export interface FilterMultiSelectProps {
    selectedFilterField: FilterConfig;
    selectedOperator?: OperatorConfig;
    selectedValues: SourceCriteriaFilterValue[];
    dropdownOpen: boolean;
    closeEditMode: () => void;
    setSelectedValues: React.Dispatch<React.SetStateAction<SourceCriteriaFilterValue[] | undefined>>;
    setDropdownOpen: React.Dispatch<React.SetStateAction<boolean>>;
    setIsValidating: React.Dispatch<React.SetStateAction<boolean>>;
    staticOptions?: Option[];
}

const FilterMultiSelect: React.FC<FilterMultiSelectProps> = ({
    selectedFilterField,
    selectedOperator,
    selectedValues,
    dropdownOpen,
    closeEditMode,
    setSelectedValues,
    setDropdownOpen,
    setIsValidating,
}) => {
    const {
        inputRef,
        options,
        selectedOptions,
        searchValue,
        showLoading,
        isExactMatchOperator,
        overLimit,
        focusIndex,
        removeItem,
        setSearchValue,
        onPasteValues,
        handleSearchInputKeyDown,
        onCheckedChanged,
        updateValue,
    } = useMultiSelect({
        selectedFilterField,
        selectedOperator,
        selectedValues,
        dropdownOpen,
        closeEditMode,
        setSelectedValues,
        setDropdownOpen,
        setIsValidating,

        // Some fields have static picklist options that don't need to be fetched
        staticOptions:
            selectedFilterField.picklistValues?.map((v: string) => ({
                value: v,
                label: v,
                checked: false,
            })) ?? undefined,
    });

    function handleInteractOutside(event: any): void {
        if (
            selectedFilterField.identifier !== event.target.innerText &&
            event.target !== inputRef.current &&
            !event.target.closest("#dropdown-arrow")
        ) {
            setDropdownOpen(false);
        }
    }

    const maxSupportedValues = getFilterMaxSupportedValuesForField(selectedFilterField.identifier);

    return (
        <Popover.Root modal={false} open={dropdownOpen}>
            <Popover.Anchor className="w-full">
                <div className="flex flex-col">
                    <div
                        aria-busy={overLimit}
                        className="flex item-start w-full justify-between border rounded-md p-[3px] text-sm text-ui-900 border-ui-300/[0.32] placeholder:text-dark-400 outline outline-2 outline-transparent
                    hover:border-ui-300 hover:active-within:border-transparent hover:focus-within:border-transparent
                    active-within:border-transparent active-within:outline-blue-300
                    focus-within:border-transparent focus-within:outline-blue-300
                    aria-busy:outline-2 aria-busy:outline-error aria-busy:border-transparent aria-busy:focus-within:outline-2 aria-busy:focus-within:outline-error
                    "
                    >
                        <div className="flex flex-row flex-wrap gap-1 w-full">
                            {selectedOptions.map(selectedOption => (
                                <SelectedOptionViewer
                                    selectedFilterField={selectedFilterField}
                                    key={parseValue(selectedOption.value)}
                                    option={selectedOption}
                                    onRemove={removeItem}
                                    updateValue={updateValue}
                                />
                            ))}
                            <Input
                                value={searchValue}
                                ref={inputRef}
                                onFocusCapture={() => setDropdownOpen(isExactMatchOperator)}
                                onClick={() => setDropdownOpen(isExactMatchOperator)}
                                onKeyDown={handleSearchInputKeyDown}
                                onPaste={onPasteValues}
                                defaultClassName="w-full border-0 hover:border-0 focus:border-0 focus:border-0 outline-none"
                                autoFocus
                                placeholder={!selectedOptions.length ? selectedOperator?.placeholders[0] : ""}
                                onChange={event => setSearchValue(event.target.value)}
                                onFocus={event => {
                                    event.preventDefault();
                                    event.stopPropagation();
                                }}
                                containerClassName="flex-grow min-w-[10px] w-fit ml-2 px-0 py-1 m-0"
                            />
                        </div>
                        {isExactMatchOperator && (
                            <Button
                                id="dropdown-arrow"
                                variant="ghost"
                                className="ml-auto mt-[6px] mr-1 h-full"
                                size="small"
                                onClick={() => setDropdownOpen(prevValue => !prevValue)}
                                aria-label="open dropdown"
                            >
                                <ChevronDownIcon
                                    className={`h-full w-4 transition-transform duration-200 ${dropdownOpen ? "rotate-180" : "rotate-0"}`}
                                />
                            </Button>
                        )}
                    </div>
                    {overLimit && (
                        <span className="text-error text-[12px] ml-1 mt-1">{`Values are limited to ${maxSupportedValues}`}</span>
                    )}
                </div>
            </Popover.Anchor>

            <Popover.Portal>
                <Popover.Content
                    onFocusOutside={e => e.preventDefault()}
                    onOpenAutoFocus={e => e.preventDefault()}
                    onInteractOutside={handleInteractOutside}
                    sideOffset={2}
                    collisionPadding={10}
                    className="outline-none w-[var(--radix-popper-anchor-width)] data-[side=top]:shadow-dropdown-t data-[side=bottom]:shadow-dropdown-b data-[side=top]:animate-slideDownAndFade data-[side=bottom]:animate-slideUpAndFade bg-white rounded-md z-20 border border-ui-300/[0.19]"
                    align="start"
                >
                    <div className="py-2 max-h-[min(var(--radix-popover-content-available-height),330px)] overflow-y-scroll">
                        {showLoading && (
                            <div className="flex fle-row justify-center items-center m-2">
                                <span className="text-ui-400 text-[14px] mr-2">Loading</span>
                                <CircularProgressBar size="small" />
                            </div>
                        )}
                        {!showLoading && options.length === 0 && (
                            <div className="m-3 text-ui-400 text-[14px]">No options found.</div>
                        )}
                        {!showLoading &&
                            selectedFilterField &&
                            options.map((option, index) => (
                                <OptionItem
                                    selectedFilterField={selectedFilterField}
                                    option={option}
                                    key={parseValue(option.value)}
                                    onCheckedChanged={onCheckedChanged}
                                    index={index}
                                    focusIndex={focusIndex}
                                    searchText={searchValue}
                                />
                            ))}
                        {!showLoading && options.length === MAX_FILTER_VALUES && (
                            <div className="text-ui-700 text-sm text-center border-t border-t-1 border-t-ui-50 pt-2">
                                Current search limited to {MAX_FILTER_VALUES} results. Start typing to refine your search
                            </div>
                        )}
                    </div>
                </Popover.Content>
            </Popover.Portal>
        </Popover.Root>
    );
};

export default FilterMultiSelect;
