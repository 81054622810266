import { useState } from "react";
import { Button, ButtonProps } from "@/components/atoms/Button/Button";
import { useGetSubscriptionProductsQuery, useSubscriptionQuery } from "@/api/users";
import { UpgradePlanModal } from "@/components/organisms/subscription/UpgradePlan/UpgradePlanModal";

interface Props {
    triggerProps?: ButtonProps;
    onClose?: () => void;
}

export const UpgradePlan = ({ triggerProps, onClose }: Props) => {
    const [modalOpened, setModalOpened] = useState(false);
    const { data: products } = useGetSubscriptionProductsQuery();
    const { data: subscription } = useSubscriptionQuery(undefined);

    return (
        <>
            <Button
                data-testid="upgrade-btn"
                variant="secondary"
                {...triggerProps}
                onClick={() => setModalOpened(true)}
            >
                Upgrade Plan
            </Button>

            {modalOpened && subscription && products && (
                <UpgradePlanModal
                    subscription={subscription}
                    subscriptionProducts={products}
                    closeModal={() => {
                        setModalOpened(false);
                        onClose?.();
                    }}
                />
            )}
        </>
    );
};
