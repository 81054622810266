import { useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";

import { Button } from "@/components/atoms/Button/Button";
import ArrowRightIcon from "@/assets/icons/arrow-right.svg?react";
import CheckmarkIcon from "@/assets/icons/checkmark-outline.svg?react";
import { PaymentMethod, SubscriptionProducts } from "@/types/subscription";
import { CheckoutModal } from "@/components/organisms/subscription/UpgradePlan/CheckoutModal";

interface Props {
    audienceLimit: number;
    currentSubscriptionItemId: string;
    paymentMethod: PaymentMethod;
    closeUpgradeOptions: () => void;
    currentAddAudienceSubscriptionItemId: string;
    subscriptionProducts: SubscriptionProducts;
}

export const GrowUpgradeCard = ({ audienceLimit, closeUpgradeOptions, ...props }: Props) => {
    const [selectedInterval, setSelectedInterval] = useState<"yearly" | "monthly">("yearly");

    const activeClass = "bg-white rounded-lg border !border-primary";

    const isYearly = selectedInterval === "yearly";

    const [searchParams, setSearchParams] = useSearchParams();

    const checkoutOpened = useMemo(() => searchParams.has("checkout"), [searchParams]);

    const close = (closePlans: boolean = false) => {
        if (searchParams.has("checkout")) {
            searchParams.delete("checkout");
            setSearchParams(searchParams);
        }

        closePlans && closeUpgradeOptions();
    };

    return (
        <div className="w-[320px] flex flex-col gap-4 px-8 py-6 border rounded-2xl border-blue-200 z-50">
            <div className="w-full flex items-center py-0.5 px-2  gap-2 bg-ui-300/[0.19] rounded-lg text-ui-900 text-12">
                <div>Billed:</div>
                <div
                    className={`flex items-center gap-1 cursor-pointer border border-transparent px-2 py-1.5 ${selectedInterval === "yearly" && activeClass}`}
                    onClick={() => setSelectedInterval("yearly")}
                >
                    <span>Annually</span>
                    <div className="px-1 text-10 text-green-500 bg-green-100 rounded text-nowrap">Save 15%</div>
                </div>
                <div
                    className={`flex ml-auto items-center cursor-pointer border border-transparent px-2 py-1.5 ${selectedInterval === "monthly" && activeClass}`}
                    onClick={() => setSelectedInterval("monthly")}
                >
                    <div>Monthly</div>
                </div>
            </div>

            <div className="flex gap-2 items-center">
                <span className="text-ui-900 text-[48px] leading-[57px]">{audienceLimit}</span>
                <span className="text-12 text-ui-700">
                    active <br /> audiences
                </span>
            </div>

            <div>
                {!isYearly && <div className="text-16 font-medium text-transparent">_</div>}
                {isYearly && <div className="text-16 line-through text-ui-400">$1,500</div>}
                <div className="flex gap-2 items-center">
                    <span className="text-ui-900 text-[48px] leading-[57px]">{isYearly ? "$1,250" : "$1,500"}</span>
                    <span className="text-12 text-ui-700">per month</span>
                </div>
            </div>

            <Button
                onClick={() => {
                    setSearchParams({ checkout: "opened" });
                }}
            >
                Checkout in Stripe
                <ArrowRightIcon className="text-white ml-1" />
            </Button>

            <div className="flex gap-2">
                <CheckmarkIcon className="shrink-0 text-primary" />
                <div className="text-14 text-ui-700">Best for $10K - $49K in monthly ad spend</div>
            </div>

            {checkoutOpened && <CheckoutModal frequency={selectedInterval} close={close} {...props} />}
        </div>
    );
};
