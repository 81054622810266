import {
    FilterEntityTypes,
    FilterFields,
    FilterOperators,
    GroupLogicalOperator,
    SourceCriteriaFilterValue,
} from "@primer/filters/types";

export enum Destination {
    GOOGLE = "google",
    META = "meta",
    LINKEDIN = "linkedIn",
    SALESFORCE = "salesforce",
    CSV = "csv",
}

export enum AudienceStatus {
    DRAFT = "draft",
    RUNNING = "running",
    SYNCED = "synced",
    INCOMPLETE = "incomplete",
    ARCHIVED = "archived",
}

export enum AudienceMode {
    AD = "ad",
    OUTBOUND = "outbound",
}

export enum ScheduleFrequency {
    DAILY = "daily",
    WEEKLY = "weekly",
    MONTHLY = "monthly",
}

export enum ScheduleWeekDays {
    SUNDAY,
    MONDAY,
    TUESDAY,
    WEDNESDAY,
    THURSDAY,
    FRIDAY,
    SATURDAY,
}

export interface DestinationsSchema {
    [Destination.META]?: boolean;
    [Destination.GOOGLE]?: boolean;
    [Destination.LINKEDIN]?: boolean;
    [Destination.SALESFORCE]?: boolean;
    [Destination.CSV]?: boolean;
}

interface ChartValueSchema {
    key: string;
    label?: string;
    value: number;
}

export type ExtraChartValueSchema = ChartValueSchema & {
    percentage: number;
};

export enum SummaryMetricColumn {
    JOB_TITLE = "Job Title",
    ANNUAL_REVENUE = "Annual Revenue",
    HEADCOUNT = "Headcount",
    INDUSTRY = "Industry",
    COMPANY_LOCATION = "Company Location",
    COMPANY_COUNTRY = "Company Country",
    COMPANY_STATE = "Company State",
    COMPANY_CITY = "Company City",
    SENIORITY = "Seniority",
    COMPANY_DOMAIN = "Companies By People",
}
export interface ChartSchema {
    name?: string;
    total?: number;
    values: ChartValueSchema[];
}

export type ExtraChartSchema = {
    category?: string;
    name?: string;
    total: number;
    values: ExtraChartValueSchema[];
};

export interface AudiencePreview {
    id: number;
    first_name?: string;
    last_name?: string;
    location?: string;
    job_title_original?: string;
    seniority?: string;
    all_departments?: string[];
    company_name?: string;
    company_location?: string;
    company_headcount?: number;
    company_industries?: string[];
    company_keywords?: string[];
    company_technologies?: string[];
    company_annual_revenue_bucket?: string;
    company_founded_year?: number;
    company_domain?: string;
}

export interface AudienceShapeHeuristicsSchema {
    people_count: number;
    companies_count: number;
    preview_key?: string;
    destinations?: Destination[];
    heuristics?: {
        limit?: number;
        top?: ChartSchema[];
    };
    created_at: Date;
    preview?: { data: AudiencePreview[]; count: number };
}

interface GetAudienceBuildSchema {
    id: number;
    heuristics: {
        summary: ChartSchema[];
        top: ChartSchema[];
        fullRates: ChartSchema[];
    };
}

export interface SourceCriteriaFilter {
    unique_id: string;
    entity_type?: FilterEntityTypes;
    field?: FilterFields | string;
    operator?: FilterOperators;
    values?: SourceCriteriaFilterValue[];
    isEmpty?: boolean;
    isDuplicated?: boolean;
    customError?: string;
    dataType?: string;
    objectType?: string;
    instanceId?: string;
    mappingTable?: string;
    path?: string;
}
export interface SourceCriteriaGroup {
    operator: GroupLogicalOperator;
    filters: SourceCriteriaFilter[];
    groups: SourceCriteriaGroup[];
}

interface GetAudienceShapeSchema {
    id: string;
    source_criteria?: null | {
        target_entity_type: FilterEntityTypes;
        group: SourceCriteriaGroup;
    };
    enrichment_criteria: any;
    heuristics?: AudienceShapeHeuristicsSchema;
    build?: GetAudienceBuildSchema;
}

export interface Audience {
    id: string;
    name: string;
    status: AudienceStatus;
    isFavorite: boolean;
    isArchived: boolean;
    mode: AudienceMode;
    shape: GetAudienceShapeSchema;
    lastUpdatedAt: string;
    submittedAt?: string;
    nextSync: string;
    hasError: boolean;
    errors: string[];
    syncSettings: Destination[];
    destinations?: Destination[];
    companies: number | null;
    people: number | null;
    companies_estimated?: number | null;
    people_estimated?: number | null;
    companies_build?: number | null;
    people_build?: number | null;
}

export interface SubscriptionStatisticsOutput {
    audiencesMaxLimit: number;
    audiencesUsed: number;
}

export interface CrmMatchRates {
    account: CrmMatchRate | null;
    contact: CrmMatchRate | null;
    lead: CrmMatchRate | null;
}

export interface CrmMatchRate {
    id: string;
    created_at: string;
    updated_at: string;
    mapping_kind: FirstPartySyncObject;
    instance_id: string;
    source_table: string;
    target_table: string;
    match_rate: number;
    detailed_match_rate: { [key: string]: any };
    source_count: number;
    target_count: number;
    started_at: string;
    finished_at: string;
    error: string;
}

export enum FirstPartySyncObject {
    LEAD = "lead",
    CONTACT = "contact",
    ACCOUNT = "account",
}
