import { ReactNode } from "react";
import { NavLink } from "react-router-dom";

import { SidebarLink } from "@/components/layout/SidebarLink";
import { UserProfile } from "@/components/layout/UserProfile";

import PrimerLogo from "@/assets/icons/logo.svg?react";
import IconSupport from "@/assets/icons/icon-support.svg?react";
import IconAudiences from "@/assets/icons/icon-audiences.svg?react";
import IconIntegrations from "@/assets/icons/icon-integrations.svg?react";
import IconSettings from "@/assets/icons/settings.svg?react";
import { useLocation } from "react-router";
import cn from "classnames";
import { FeatureFlagsEnum, useFeatureFlag } from "@/hooks";
import { useGetOnboardingQuery } from "@/api/users";
import { OnboardingHub } from "@/components/organisms/onboarding/OnboardingHub";

export const Navigation = ({ children }: { children: ReactNode }) => {
    const { pathname } = useLocation();
    const paymentPitchEnabled = useFeatureFlag(FeatureFlagsEnum.PAYMENT_PITCH);
    const onboardingPitchEnabled = useFeatureFlag(FeatureFlagsEnum.ONBOARDING_PITCH);

    const { data: onboarding } = useGetOnboardingQuery(undefined, { skip: !onboardingPitchEnabled });

    const hideSidebar =
        /\/audiences\/(new|\d+|import-csv|import-loading)$/.test(pathname) ||
        (onboarding && !onboarding?.onboarding_completed);

    return (
        <div className="flex bg-dark-100">
            <div className={cn("h-screen w-56 flex flex-col shrink-0 p-1.5 visible", { "hidden": hideSidebar })}>
                <nav className="flex flex-col flex-grow bg-blue-900 rounded-lg">
                    <div className="py-10 text-left ml-5">
                        <NavLink to="/" className="inline-block">
                            {!hideSidebar && <PrimerLogo />}
                        </NavLink>
                    </div>
                    <div className="px-6 flex-grow">
                        <ul className="text-white space-y-2">
                            <SidebarLink to="/audiences" icon={IconAudiences}>
                                Audiences
                            </SidebarLink>
                            <SidebarLink to="/integrations" icon={IconIntegrations}>
                                Integrations
                            </SidebarLink>
                            {paymentPitchEnabled && (
                                <SidebarLink to="/settings" icon={IconSettings}>
                                    Settings
                                </SidebarLink>
                            )}
                            <SidebarLink target="_blank" to="https://support.sayprimer.com/primer" icon={IconSupport}>
                                Help Center
                            </SidebarLink>
                        </ul>
                    </div>
                    <UserProfile />
                </nav>
            </div>

            <main id="main-element" className="h-screen w-screen flex-grow overflow-x-hidden">
                {children}
                {onboardingPitchEnabled && <OnboardingHub />}
            </main>
        </div>
    );
};
