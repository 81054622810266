import { useMemo } from "react";
import { GetConnectionsResponse } from "@/types/api";
import { Button } from "@/components/atoms/Button/Button";
import { useUpdateOnboardingMutation } from "@/api/users";
import { useGetConnectionsQuery } from "@/api/integrations";
import InfoIcon from "@/assets/icons/info-outline.svg?react";
import { ConnectionStatus, Service } from "@/types/integrations";
import ArrowRightIcon from "@/assets/icons/arrow-right.svg?react";
import AddPlatformsIcon from "@/assets/images/onboarding/ad-platfrom-connect.svg";
import { useOnboarding } from "@/components/organisms/onboarding/OnboardingContext";
import { adPlatformCards, getIconForService, getOAuthUrl } from "@/config/integrations";
import { ConnectionStatusPill } from "@/components/organisms/integrations/ConnectionStatus";
import { OnboardingSkeleton, OnboardingWrapper } from "@/components/organisms/onboarding/OnboardingWrapper";

export const AddPlatformsConnect = () => {
    const { onboarding, setCurrentStep } = useOnboarding();
    const [updateOnboarding] = useUpdateOnboardingMutation();

    const { data: connections, isLoading } = useGetConnectionsQuery(
        { enabled: true },
        { refetchOnMountOrArgChange: true },
    );

    const atLeastOnePlatformConnected = useMemo(
        () =>
            adPlatformCards.some(({ service }) => {
                const item = connections?.find(connection => connection.provider === service);
                return item?.state === ConnectionStatus.CONNECTED || item?.state === ConnectionStatus.INITIATED;
            }),
        [connections],
    );

    const nextStep = async () => {
        atLeastOnePlatformConnected &&
            !onboarding.add_platform_connected &&
            (await updateOnboarding({ id: onboarding.id, add_platform_connected: true }));

        setCurrentStep(4);
    };

    const alreadyConnectedByOtherUser = !isLoading && atLeastOnePlatformConnected && !onboarding.add_platform_connected;

    if (!connections) return <OnboardingSkeleton />;

    return (
        <OnboardingWrapper imgUrl={AddPlatformsIcon}>
            <div className="w-full">
                <h1 className="text-h1 mb-2">Connect your ad accounts</h1>
                <p className="text-16">Send custom audiences to all major ad platforms.</p>
            </div>

            <div className="space-y-2 w-full">
                {adPlatformCards.map(({ service, name }) => (
                    <AddPlatformItem key={service} service={service} name={name} connections={connections} />
                ))}
            </div>

            {alreadyConnectedByOtherUser && (
                <div className="w-full flex items-center p-3 rounded-lg bg-blue-50 border border-blue-200 space-x-2 text-blue-870 !mt-2">
                    <InfoIcon className="mb-0.5" />
                    <div className="text-14">Already connected for your org by another user.</div>
                </div>
            )}

            {!atLeastOnePlatformConnected && (
                <Button variant="ghost" className="flex items-center gap-0.5 !text-ui-300" onClick={nextStep}>
                    Skip
                    <ArrowRightIcon />
                </Button>
            )}

            {atLeastOnePlatformConnected && (
                <Button className="flex items-center gap-0.5" onClick={nextStep}>
                    Next
                    <ArrowRightIcon />
                </Button>
            )}
        </OnboardingWrapper>
    );
};

interface Props {
    name: string;
    service: Service;
    connections: GetConnectionsResponse | undefined;
}
export const AddPlatformItem = ({ service, name, connections }: Props) => {
    const icon = getIconForService(service, "h-9 w-9");

    const connection = connections?.find(({ provider }) => provider === service);
    const isConnected =
        connection?.state === ConnectionStatus.CONNECTED || connection?.state === ConnectionStatus.INITIATED;

    const connect = () => {
        const connectUrl = getOAuthUrl(service);
        if (connectUrl) window.location.href = connectUrl;
    };

    return (
        <div className="flex items-center justify-between px-6 py-4 bg-white border border-ui-200 rounded-lg">
            <div className="flex items-center gap-4">
                {icon}
                <div>
                    <div className="text-16">{name}</div>
                    <span className="text-10 text-error">{connection?.error?.message}</span>
                </div>
            </div>

            {!isConnected && (
                <Button onClick={connect} attributes={{ "data-ph-capture-attribute-service-name": service }}>
                    Connect
                </Button>
            )}
            {isConnected && <ConnectionStatusPill status={connection.state} service={service} />}
        </div>
    );
};
