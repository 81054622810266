import { Link } from "react-router-dom";
import { differenceInCalendarDays } from "date-fns";
import { Subscription } from "@/types/subscription";
import { Button } from "@/components/atoms/Button/Button";
import GrowPlanIcon from "@/assets/icons/grow-plan.svg?react";
import { SUBSCRIPTION_TRIAL_PERIOD } from "@/constants/subscriptions";

interface Props {
    subscription: Subscription;
    audiencesLimit?: number;
}

export const TrialBanner = ({ subscription, audiencesLimit = 8 }: Props) => {
    const { trialEnd, startDate } = subscription;

    const currentDate = new Date();
    const endDate = new Date(trialEnd * 1000);

    const sevenDaysPassed = differenceInCalendarDays(currentDate, startDate * 1000) > 7;

    const daysLeft = differenceInCalendarDays(endDate, currentDate);
    const daysLeftPercents = daysLeft < SUBSCRIPTION_TRIAL_PERIOD ? (daysLeft / SUBSCRIPTION_TRIAL_PERIOD) * 100 : 100;

    if (Object.keys(subscription.paymentMethod).length || !sevenDaysPassed) return null;

    return (
        <div className="flex items-center mx-12 mb-2 px-6 py-4 bg-blue-900 space-x-6 rounded-md">
            <div className="flex items-center gap-3 shrink-0">
                <GrowPlanIcon />

                <div>
                    <div className="text-white text-16 font-medium">Primer Grow</div>
                    <div className="text-12 text-center text-ui-900 bg-yellow-500 px-2 py-0.5 rounded font-medium">
                        FREE TRIAL
                    </div>
                </div>
            </div>

            <p data-testid="trial-text" className="flex-1 flex flex-col text-14 text-white truncate">
                {`Enjoy your trial with access to ${audiencesLimit} audiences for 30 days!`}
                <span className="mt-0.5"> Add payment info to continue using Primer once your free trial ends.</span>
            </p>

            <div>
                <div className="flex items-center gap-1 mb-2">
                    <div className="text-blue-300 text-16 font-semibold">{daysLeft}</div>
                    <div className="text-14 text-white">trial days left</div>
                </div>
                <div className="w-40 h-1 rounded bg-ui-200">
                    <div className="h-full rounded bg-blue-300" style={{ width: `${daysLeftPercents}%` }}></div>
                </div>
            </div>

            <Button asChild variant="secondary">
                <Link to="/settings">Add Payment</Link>
            </Button>
        </div>
    );
};
