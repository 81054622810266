import { throttle } from "lodash";
import { AudienceShapeHeuristicsSchema, AudienceStatus } from "@/types/audience";
import { Button } from "@/components/atoms/Button/Button.tsx";
import UnarchiveIcon from "@/assets/icons/unarchive.svg?react";
import ThreeDotsIcon from "@/assets/icons/three-dots.svg?react";
import { CheckIcon } from "@radix-ui/react-icons";
import ViewRunsIcon from "@/assets/icons/view-runs.svg?react";
import { GetAudienceResponse, RunAudienceRequest } from "@/types/api";
import Skeleton from "@/components/atoms/Skeleton/Skeleton";
import { DropdownMenu, DropdownMenuTrigger } from "@/components/atoms/DropdownMenu/DropdownMenu.tsx";
import CircularProgressBar from "@/components/atoms/CircularProgressBar/CircularProgressBar.tsx";
import Timer from "@/components/molecules/Timer/Timer.tsx";
import { AudienceContextMenu } from "@/components/molecules/AudienceContextMenu/AudienceContextMenu";
import { useUpdateAudience } from "@/hooks/useUpdateAudience";
import RerunIcon from "@/assets/icons/rerun.svg?react";
import { useEffect, useState } from "react";
import { AudienceRunsModal } from "@/components/organisms/audiences/RunsModal";
import { CapWarningModal } from "./CapWarningModal";
import postHog from "posthog-js";
import { OutOfAudiencesModal } from "@/components/organisms/audiences/AudiencesInfoBar/OutOfAudiencesModal";
import { api } from "@/api";
import { FeatureFlagsEnum, useFeatureFlag } from "@/hooks";
import { skipToken } from "@reduxjs/toolkit/query";
import { useSearchParams } from "react-router-dom";
import { useGetOnboardingQuery, useUpdateOnboardingMutation } from "@/api/users";
import { Tooltip } from "@/components/atoms/Tooltip/Tooltip";
import { useAudienceHeaderManagement } from "../../hooks/useAudienceHeaderManagement";

export interface AudienceHeaderCTAProps {
    audience?: GetAudienceResponse;
    criteriaAssets?: AudienceShapeHeuristicsSchema;
    audienceId?: string;
    status?: AudienceStatus;
    loading?: boolean;
    isBuilding?: boolean;
    isUpdating?: boolean;
    isLoadingEstimateInfo: boolean;
    // lastUpdatedAt?: string;
    submittedAt?: string;
    runAudience: (req: RunAudienceRequest) => Promise<null>;
}

const AudienceHeaderCTA = (props: AudienceHeaderCTAProps) => {
    const {
        audience,
        status: statusFromApi,
        audienceId,
        submittedAt,
        loading = false,
        isBuilding = false,
        isUpdating = false,
        runAudience,
    } = props;
    const paymentPitchEnabled = useFeatureFlag(FeatureFlagsEnum.PAYMENT_PITCH);
    const onboardingPitchEnabled = useFeatureFlag(FeatureFlagsEnum.ONBOARDING_PITCH);

    const [searchParams,] = useSearchParams();

    const { unarchiveAudience } = useUpdateAudience(audienceId);

    const [outOfAudiencesModalOpened, setOutOfAudiencesModalOpened] = useState(false);

    const {
        isFinishDisabled,
        audienceTooBig,
        disabledTooltip
    } = useAudienceHeaderManagement(props);

    const { data: onboarding } = useGetOnboardingQuery(undefined, { skip: !onboardingPitchEnabled });
    const [updateOnboarding] = useUpdateOnboardingMutation();

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const { data: statistics } = api.endpoints.statistics.useQueryState(paymentPitchEnabled ? undefined : skipToken);

    const allAudiencesUsed = statistics?.audiencesMaxLimit <= statistics?.audiencesUsed;

    const handleUnarchiveAudience = async () => {
        if (allAudiencesUsed) return setOutOfAudiencesModalOpened(true);
        await unarchiveAudience();
    };

    const [status, setStatus] = useState<AudienceStatus | undefined>(statusFromApi);

    useEffect(() => {
        setStatus(statusFromApi);
    }, [statusFromApi]);

    const runAudienceCTA = throttle(
        async (isRerun?: boolean) => {
            // When an audience is ran, send event to posthog
            postHog.capture("Audience run initiated", {
                buttonId: "finish-audience-button",
                audienceId,
                isRerun: isRerun ?? false,
                destinations: Object.keys(audience?.destinations ?? {}),
            });

            if (!isRerun && allAudiencesUsed) return setOutOfAudiencesModalOpened(true);

            if (!confirmedAudienceCap && audienceTooBig) {
                setCapWarningModalOpened(true);
            } else if (audienceId && !isBuilding) {
                await runAudience({ audienceId });
                setStatus(AudienceStatus.RUNNING);
                if (onboarding && !onboarding.audience_run) updateOnboarding({ id: onboarding.id, audience_run: true });
            }
        },
        500,
        { trailing: false },
    );

    const [viewRunsOpened, setViewRunsOpened] = useState(searchParams.get("showRuns") === "yes");
    const [capWarningModalOpen, setCapWarningModalOpened] = useState(false);
    const [confirmedAudienceCap, setConfirmedAudienceCap] = useState(false);

    useEffect(() => {
        if (searchParams.get("showRuns") === "yes") setViewRunsOpened(true);
    }, [searchParams]);

    useEffect(() => {
        if (confirmedAudienceCap) runAudienceCTA();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [confirmedAudienceCap]);

    if (loading) return <Skeleton className={"h-11 w-20 mr-2 rounded-lg"} />;

    const cofirmAudienceCap = () => {
        setConfirmedAudienceCap(true);
        setCapWarningModalOpened(false);
    };

    const dropdown = (
        <DropdownMenu>
            <DropdownMenuTrigger asChild>
                <Button variant="ghost" size="icon" className="!h-9" role="menu">
                    <ThreeDotsIcon />
                </Button>
            </DropdownMenuTrigger>
            {audienceId && status && (
                <AudienceContextMenu
                    audienceId={audienceId}
                    page="audience"
                    status={status}
                    handleViewRuns={() => setViewRunsOpened(true)}
                />
            )}
        </DropdownMenu>
    );

    const finishAndRunButton = (
        <Button
            onClick={() => runAudienceCTA()}
            disabled={isFinishDisabled}
            loading={isBuilding}
            className="!h-9"
        >
            <CheckIcon className="w-5 h-5 mr-1" />
            Finish & Run
        </Button>
    );
    let content = null;

    switch (status) {
        case AudienceStatus.ARCHIVED:
            content = (
                <>
                    <Button variant="secondary" onClick={handleUnarchiveAudience} loading={isUpdating}>
                        <UnarchiveIcon className="w-5 h-5 mr-1" />
                        Unarchive
                    </Button>
                    {dropdown}
                </>
            );
            break;
        case AudienceStatus.RUNNING:
            content = (
                <>
                    <CircularProgressBar />
                    <div className="flex items-center">
                        <Timer startTime={submittedAt ? new Date(submittedAt) : undefined} />
                    </div>
                    <Button onClick={() => setViewRunsOpened(true)} className="mx-2">
                        <ViewRunsIcon className="w-5 h-5 mr-1" />
                        View Run Details
                    </Button>
                    {dropdown}
                </>
            );
            break;
        case AudienceStatus.DRAFT:
            content = <>
                {isFinishDisabled && disabledTooltip ? (
                    <Tooltip content={disabledTooltip} className="!max-w-[240px] text-xs" side="bottom">
                        {finishAndRunButton}
                    </Tooltip>
                ) : finishAndRunButton}
                {dropdown}
            </>;
            break;
        case AudienceStatus.SYNCED:
            content = (
                <>
                    <Button className="mr-4" variant="outline" onClick={() => setViewRunsOpened(true)}>
                        <ViewRunsIcon className="w-5 h-5 mr-1 [&_path]:fill-dark-900" />
                        View Runs
                    </Button>
                    <Button
                        onClick={() => runAudienceCTA(true)}
                        disabled={isFinishDisabled}
                        loading={isBuilding}
                        className="!h-9"
                    >
                        <RerunIcon className="w-5 h-5 mr-1" />
                        Rerun
                    </Button>
                    {dropdown}
                </>
            );
            break;
        case AudienceStatus.INCOMPLETE:
            content = (
                <>
                    <Button onClick={() => setViewRunsOpened(true)}>
                        <ViewRunsIcon className="w-5 h-5 mr-1" />
                        View Errors
                    </Button>
                    {dropdown}
                </>
            );
            break;
    }

    return (
        <div className="flex items-center">
            {viewRunsOpened && (
                <AudienceRunsModal
                    audienceId={audienceId!}
                    audienceName={audience?.name || ""}
                    audienceStatus={status}
                    disabled={isFinishDisabled}
                    onClose={() => {
                        searchParams.delete("showRuns");
                        setViewRunsOpened(false);
                    }}
                />
            )}
            {capWarningModalOpen && (
                <CapWarningModal onConfirm={cofirmAudienceCap} onClose={() => setCapWarningModalOpened(false)} />
            )}

            {outOfAudiencesModalOpened && <OutOfAudiencesModal close={() => setOutOfAudiencesModalOpened(false)} />}
            {content}
        </div>
    );
};

export default AudienceHeaderCTA;
