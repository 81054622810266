import cn from "clsx";
import { Button } from "@/components/atoms/Button/Button";
import ArrowLeftIcon from "@/assets/icons/arrow-left.svg?react";
import { useOnboarding } from "@/components/organisms/onboarding/OnboardingContext";

export const OnboardingNavigation = () => {
    const { currentStep, setCurrentStep } = useOnboarding();

    const showBackButton = currentStep > 1 && currentStep < 4;
    return (
        <div className="flex space-x-1">
            {showBackButton && (
                <Button
                    variant="secondary"
                    size="icon"
                    className="flex items-center gap-0.5"
                    onClick={() => setCurrentStep(currentStep - 1)}
                >
                    <ArrowLeftIcon />
                </Button>
            )}

            <div className="flex items-center justify-between px-4 gap-1 w-[250px] bg-white border border-ui-300/[0.19] rounded-lg cursor-pointer">
                {[1, 2, 3, 4].map(step => (
                    <OnboardingNavigationStep key={step} step={step} />
                ))}
            </div>

            {showBackButton && <div className="w-10 h-10 bg-transparent"></div>}
        </div>
    );
};

export const OnboardingNavigationStep = ({ step }: { step: number }) => {
    const { currentStep, setCurrentStep } = useOnboarding();
    const active = step <= currentStep;

    const handleClick = () => {
        if (!active || currentStep === step) return;
        setCurrentStep(step);
    };

    return (
        <div className="py-[17px] flex-1" onClick={handleClick}>
            <div className={cn("bg-ui-200 rounded h-1", active && "!bg-primary")}></div>
        </div>
    );
};
