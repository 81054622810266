import { SplitPaneLayout } from "@/components/layout";
import { OrganizationList, RedirectToSignIn, useAuth, useClerk } from "@clerk/clerk-react";

import JoinGraphic from "@/assets/icons/organization-join.svg";

export const OrganizationPick = () => {
    const { signOut } = useClerk();
    const { isSignedIn } = useAuth();

    if (!isSignedIn) return <RedirectToSignIn />;

    return (
        <SplitPaneLayout imgUrl={JoinGraphic} left>
            <div className="relative [&_#back-to-login]:has-[.cl-organizationList-root]:inline-block">
                <OrganizationList
                    afterSelectOrganizationUrl="/audiences?signed=true"
                    afterCreateOrganizationUrl="/audiences?signed=true"
                    hidePersonal
                />

                <button
                    onClick={() => signOut()}
                    id="back-to-login"
                    className="absolute hidden top-12 right-8 z-10 text-xs leading-6 font-medium text-primary hover:text-blue-850 hover:underline tracking-wider focus:outline-none"
                >
                    BACK TO LOGIN
                </button>
            </div>
        </SplitPaneLayout>
    );
};
