import cn from "classnames";
import IndentIcon from "@/assets/icons/indent.svg?react";
import CloseIcon from "@/assets/icons/close-red.svg?react";
import SyncedIcon from "@/assets/icons/circle-check.svg?react";
import LinkIcon from "@/assets/icons/link-icon.svg?react";
import CircularProgressBar from "@/components/atoms/CircularProgressBar/CircularProgressBar";
import { AudienceStatus } from "@/types/audience";
import { Tooltip } from "@/components/atoms/Tooltip/Tooltip";
import { useGetOnboardingQuery, useUpdateOnboardingMutation } from "@/api/users";
import { FeatureFlagsEnum, useFeatureFlag } from "@/hooks";

interface Props {
    eventName: string;
    duration: string | JSX.Element;
    details: string | JSX.Element | null | undefined;
    success?: boolean;
    inProgress?: boolean;
    error?: string | null;
    withBorderBottom?: boolean;
    adAudienceUrl?: string | null;
}

export const RunEvent = ({
    eventName,
    duration,
    details,
    success,
    inProgress,
    error,
    withBorderBottom,
    adAudienceUrl,
}: Props) => {
    const baseClass = cn("flex items-center px-4 py-3 border-l border-ui-300/[.32]", {
        "border-b": withBorderBottom,
    });
    const bgClass = !!error && "bg-error-background";

    const onboardingPitchEnabled = useFeatureFlag(FeatureFlagsEnum.ONBOARDING_PITCH);

    const { data: onboarding } = useGetOnboardingQuery(undefined, { skip: !onboardingPitchEnabled });
    const [updateOnboarding] = useUpdateOnboardingMutation();

    const showAudienceAtDestinationPlatform = async () => {
        if (onboarding && !onboarding.ad_destination_visited) {
            await updateOnboarding({ id: onboarding!.id, ad_destination_visited: true });
        }
        window.open(adAudienceUrl as string, "_blank");
    };

    return (
        <>
            <div className={cn(baseClass, "border-l-0")}></div>
            <div className={cn(baseClass, "flex items-center space-x-1.5")}>
                <IndentIcon title="Run row event indent icon" aria-label="Run row event indent icon" />
                <span className="truncate">{eventName}</span>
            </div>
            <div className={cn(baseClass)}>{duration}</div>
            <div className={cn(baseClass, bgClass, "flex items-center space-x-2")}>
                <span className="min-w-[20px] min-h-[20px]">
                    {error && (
                        <CloseIcon
                            className="w-5 h-5"
                            title={AudienceStatus.INCOMPLETE}
                            aria-label={AudienceStatus.INCOMPLETE}
                        />
                    )}
                    {success && <SyncedIcon title={AudienceStatus.SYNCED} aria-label={AudienceStatus.SYNCED} />}
                    {inProgress && <CircularProgressBar size="x-small" />}
                </span>
                <span>{details}</span>
            </div>
            {adAudienceUrl ? (
                <Tooltip
                    side="bottom"
                    className="w-[240px]"
                    sideOffset={-17}
                    content="Access your synced audience directly in your ad account audience manager"
                >
                    <div
                        className={cn(baseClass, "flex items-center space-x-1 text-primary cursor-pointer")}
                        onClick={showAudienceAtDestinationPlatform}
                    >
                        <div className="text-14">Show Audience</div>
                        <LinkIcon className="mb-0.5" />
                    </div>
                </Tooltip>
            ) : (
                <div className={cn(baseClass)}></div>
            )}
        </>
    );
};
