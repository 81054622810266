import cn from "classnames";
import { formatDate } from "date-fns";
import { isNil, throttle } from "lodash";

import IncompleteIcon from "@/assets/icons/incomplete.svg?react";
import SyncedIcon from "@/assets/icons/circle-check.svg?react";
import { RunEvent } from "@/components/organisms/audiences/RunsModal/RunEvent";
import { Tooltip } from "@/components/atoms/Tooltip/Tooltip.tsx";
import { Button } from "@/components/atoms/Button/Button";
import CircularProgressBar from "@/components/atoms/CircularProgressBar/CircularProgressBar";
import { AudienceRun } from "@/types/api";
import { AudienceStatus } from "@/types/audience";
import { formatTimeToShortNotation, getRelativeDateString, getTimeDifference } from "@/utils/date";

import SyncEvent from "./SyncEvent";
import { DurationTimer } from "./DurationTimer";
import { formatNumber } from "@/utils/number.ts";

type Props = {
    audienceRun: AudienceRun;
    audienceName: string;
    handleRerun: (id: string) => void;
    isRerunning?: boolean;
    isLatest?: boolean;
    disabled: boolean;
};

export const RunRow = ({ audienceRun, audienceName, handleRerun, isRerunning, isLatest, disabled }: Props) => {
    const {
        id,
        syncs = [],
        createdAt,
        finishedAt,
        buildError,
        buildRequestedAt,
        builtAt,
        peopleCount,
        companiesCount,
        createdBy,
    } = audienceRun;
    const failedSyns = syncs?.filter(sync => !isNil(sync.error));
    const isCompleted = !isNil(finishedAt) && !failedSyns?.length && isNil(buildError);
    const isRunning = isNil(finishedAt);
    const isIncompleted = (failedSyns && failedSyns.length > 0 && !isNil(finishedAt)) || !isNil(buildError);

    const runDuration = isNil(finishedAt) ? (
        <DurationTimer startTime={new Date(createdAt)} />
    ) : (
        formatTimeToShortNotation(getTimeDifference(new Date(createdAt), new Date(finishedAt)))
    );
    const buildDuration = isNil(builtAt) ? (
        <DurationTimer startTime={new Date(buildRequestedAt)} />
    ) : (
        formatTimeToShortNotation(getTimeDifference(new Date(buildRequestedAt), new Date(builtAt)))
    );

    const baseClass = "px-4 py-3 border-l border-ui-300/[.32]";
    const bgClass = isIncompleted && "bg-warning-background";

    const statusText = isIncompleted ? "Run Incompleted" : isCompleted ? "Run Completed" : "Running";
    const runDetails = isIncompleted
        ? "Run is incomplete"
        : isCompleted
        ? "Run is successfully completed"
        : "Run is in progress";
    const buildDetails = 
        !isNil(buildError)
        ? "Build failed"
        : builtAt
        ? (<>
            <span className="font-medium">{formatNumber(peopleCount || companiesCount, [100000, 1000000], "")} </span>
            <span>records successfully built</span>
        </>)
        : <span className="text-ui-300">Running</span>
    const createdDetails = createdBy?.email
        ? (
            <>
                <span className="text-ui-300">by </span>
                <span className="font-medium">{createdBy.email}</span>
            </>
        )
        : ""

    const onRerun = throttle(
        () => {
            handleRerun(id);
        },
        500,
        { trailing: false },
    );

    return (
        <>
            <div className={cn(baseClass, bgClass, "flex items-center border-l-0")}>
                <Tooltip side="bottom" content={formatDate(new Date(createdAt), "yyyy-MM-dd hh:mm:ss aa")}>
                    <span>{getRelativeDateString(new Date(createdAt))}</span>
                </Tooltip>
            </div>
            <div className={cn(baseClass, bgClass, "flex items-center")}>{statusText}</div>
            <div className={cn(baseClass, bgClass, "flex items-center")}>{runDuration}</div>
            <div className={cn(baseClass, bgClass, "flex justify-between")}>
                <span className="flex items-center space-x-2">
                    <span className="min-w-[20px] min-h-[20px]">
                        {isIncompleted && (
                            <IncompleteIcon
                                className="w-5 h-5"
                                title={AudienceStatus.INCOMPLETE}
                                aria-label={AudienceStatus.INCOMPLETE}
                            />
                        )}
                        {isCompleted && <SyncedIcon title={AudienceStatus.SYNCED} aria-label={AudienceStatus.SYNCED} />}
                        {isRunning && <CircularProgressBar size="x-small" />}
                    </span>
                    <span>{runDetails}</span>
                </span>
                {isIncompleted && isLatest && failedSyns?.length && (
                    <Button variant="primary" onClick={onRerun} loading={isRerunning} disabled={disabled}>
                        Rerun Failed ({failedSyns.length})
                    </Button>
                )}
            </div>
            <div className={cn(baseClass)}></div>

            {syncs?.map(sync => <SyncEvent sync={sync} key={sync.id} audienceName={audienceName} />)}

            <RunEvent
                eventName="Build"
                duration={buildDuration}
                details={buildDetails}
                success={!!builtAt && isNil(buildError)}
                inProgress={!isNil(buildRequestedAt) && isNil(builtAt)}
                error={buildError}
            />

            <RunEvent eventName="Created" duration="-" details={createdDetails} withBorderBottom />
        </>
    );
};
