import { metrics } from "@opentelemetry/api";
import { Metric, onCLS, onFCP, onLCP, onTTFB } from "web-vitals";

export function setupWebVitalsMetrics(prefix: string) {
    const meter = metrics.getMeter("web-vitals");
    const lcp = meter.createObservableGauge(`${prefix}_lcp`);
    const cls = meter.createObservableGauge(`${prefix}_cls`);
    const ttfb = meter.createObservableGauge(`${prefix}_ttfb`);
    const fcp = meter.createObservableGauge(`${prefix}_fcp`);

    function sendToAnalytics(metric: Metric) {
        switch (metric.name) {
            case "LCP": {
                lcp.addCallback(result => {
                    result.observe(metric.value);
                });
                break;
            }
            case "CLS": {
                cls.addCallback(result => {
                    result.observe(metric.value);
                });
                break;
            }
            case "TTFB": {
                ttfb.addCallback(result => {
                    result.observe(metric.value);
                });
                break;
            }
            case "FCP": {
                fcp.addCallback(result => {
                    result.observe(metric.value);
                });
                break;
            }
            default: {
                console.log("unexpected metric name");
            }
        }
    }

    onCLS(sendToAnalytics);
    onLCP(sendToAnalytics);
    onTTFB(sendToAnalytics);
    onFCP(sendToAnalytics);
}
