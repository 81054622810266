import { TalkToSalesButton } from "./TalkToSalesButton";
import { ButtonProps } from "@/components/atoms/Button/Button";
import CheckmarkIcon from "@/assets/icons/checkmark-outline.svg?react";

interface Props {
    buttonVariant: ButtonProps["variant"];
}

export const ScalePlanCard = ({ buttonVariant }: Props) => {
    return (
        <div className="w-[320px] flex flex-col gap-4 px-8 py-6 border rounded-2xl border-blue-200 z-50">
            <div className="flex items-center py-[9px] px-2.5  bg-ui-300/[0.19] rounded-lg text-ui-900 text-12 w-max">
                <div>Annual billing only</div>
            </div>

            <div className="flex gap-2 items-center">
                <span className="text-ui-900 text-[48px] leading-[57px]">15+</span>
                <span className="text-12 text-ui-700">
                    active <br /> audiences
                </span>
            </div>

            <div>
                <div className="text-10 font-medium text-ui-700">STARTS AT</div>
                <div className="flex gap-2 items-center">
                    <span className="text-ui-900 text-[48px] leading-[57px]">$2,000</span>
                    <div className="flex flex-col justify-center">
                        <span className="text-12 text-ui-700">per month</span>
                    </div>
                </div>
            </div>

            <TalkToSalesButton variant={buttonVariant} />

            <div className="space-y-2">
                <div className="flex gap-2">
                    <CheckmarkIcon className="shrink-0 text-primary" />
                    <div className=" text-14 text-ui-700">{`Perfect for >$50K in monthly ad spend`}</div>
                </div>
                <div className="flex gap-2">
                    <CheckmarkIcon />
                    <div className=" text-14 text-ui-700">SSO options</div>
                </div>
                <div className="flex gap-2">
                    <CheckmarkIcon />
                    <div className="text-14 text-ui-700">Dedicated customer success</div>
                </div>
                <div className="flex gap-2">
                    <CheckmarkIcon />
                    <div className=" text-14 text-ui-700">Online DPA/MSA</div>
                </div>
            </div>
        </div>
    );
};
