import React from "react";
import {
    Content,
    Icon,
    Item,
    ItemText,
    Root,
    ScrollDownButton,
    ScrollUpButton,
    SelectProps as Props,
    Trigger,
    Value,
    Viewport,
} from "@radix-ui/react-select";
import { ChevronDownIcon, ChevronUpIcon } from "@radix-ui/react-icons";
import cn from "classnames";

export interface SelectItems {
    description: string;
    value: any;
}

export interface SelectProps extends Props {
    placeholder?: string;
    items: SelectItems[];
    fullWidth?: boolean;
    border?: boolean;
    className?: string;
}

const Select = React.forwardRef<HTMLButtonElement, SelectProps>(
    ({ placeholder, items, className, fullWidth = true, border = true, ...props }: SelectProps, ref) => (
        <Root {...props}>
            <Trigger
                ref={ref}
                className={cn(
                    `inline-flex ${fullWidth ? "w-full" : "w-40"} items-center whitespace-nowrap rounded-lg text-14 text-ui-700 ${border ? "border" : "border-none"} border-ui-300/[0.32] h-9 py-2 px-4 disabled:bg-ui-50 disabled:text-ui-300 disabled:border disabled:pointer-events-none disabled:bg-ui-50 disabled:border-ui-300/[0.19]`,
                    className,
                )}
            >
                <Value placeholder={placeholder} />
                <Icon className="ml-auto">
                    <ChevronDownIcon />
                </Icon>
            </Trigger>
            <Content className="overflow-hidden bg-white rounded-md shadow-dropdown z-20">
                <ScrollUpButton className="flex items-center justify-center h-[25px] bg-white cursor-default">
                    <ChevronUpIcon />
                </ScrollUpButton>
                <Viewport className="cursor-default">
                    {items.map(item => (
                        <Item
                            value={item.value}
                            key={item.value}
                            className="py-2 px-3 hover:bg-primary/10 hover:text-primary"
                        >
                            <ItemText className="mt-1">{item.description}</ItemText>
                        </Item>
                    ))}
                </Viewport>
                <ScrollDownButton className="flex items-center justify-center h-[25px] bg-white text-violet11 cursor-default">
                    <ChevronDownIcon />
                </ScrollDownButton>
            </Content>
        </Root>
    ),
);

export default Select;
