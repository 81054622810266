import { SignUp } from "@clerk/clerk-react";
import { SplitPaneLayout } from "@/components/layout";
import SignupGraphic from "@/assets/icons/signup-graphic.svg";

const Signup = () => {
    return (
        <SplitPaneLayout imgUrl={SignupGraphic}>
            <SignUp />
        </SplitPaneLayout>
    );
};

export default Signup;
