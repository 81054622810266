import { AD_DESTINATIONS } from "@/config/audience.tsx";
import { DestinationItem } from "@/pages/AudienceNew/DestinationItem.tsx";
import EnrichmentBanner from "@/pages/AudienceNew/EnrichmentBanner.tsx";
import { Card } from "@/components/atoms/Card/Card.tsx";
import { Destination, DestinationsSchema } from "@/types/audience";
import { useGetConnectionsHash } from "@/hooks/useGetConnectionsHash";
import cn from "classnames";
import * as Collapsible from '@radix-ui/react-collapsible';
import LightCaret from "@/assets/icons/light-caret.svg?react";
import { FeatureFlagsEnum, useFeatureFlag } from "@/hooks";
import { useEffect, useState } from "react";
import AccountIcon from "@/components/molecules/AccountIcon/AccountIcon";

interface DestinationCardProps {
    disabled?: boolean;
    loading?: boolean;
    selectedDestinations?: DestinationsSchema;
    setSelectedDestinations?: (destinations: DestinationsSchema, settingConnectedDestinations?: boolean) => void;
}

export const DestinationCard = ({
    selectedDestinations,
    setSelectedDestinations,
    disabled = false,
    loading = false,
}: DestinationCardProps) => {
    const isSummaryEnabled = useFeatureFlag(FeatureFlagsEnum.SUMMARY);
    const isPreviewEnabled = useFeatureFlag(FeatureFlagsEnum.PREVIEW);
    const isSplitView = isSummaryEnabled || isPreviewEnabled;

    const destinationChange = (destination: string, selected: boolean) => {
        setLockedFromAutomatic(true);
        setSelectedDestinations?.({ ...selectedDestinations, [destination]: selected });
    };

    const [open, setOpen] = useState(true);
    const [lockedFromAutomatic, setLockedFromAutomatic] = useState(false);

    useEffect(() => {
        if (!lockedFromAutomatic && isSplitView) {
            setOpen((!selectedDestinations || !Object.keys(selectedDestinations).length || Object.values(selectedDestinations).every(s => !s)));
        }
    }, [selectedDestinations, lockedFromAutomatic, isSplitView]);

    const handleOpenChange = (open: boolean) => {
        setLockedFromAutomatic(true);
        setOpen(open || !isSplitView);
    }
    const [connectionsHash, connectionsLoading] = useGetConnectionsHash();

    return (
        <Card className="!p-[24px]">
            <Collapsible.Root open={open} onOpenChange={handleOpenChange}>
                <Collapsible.Trigger asChild>
                    <div
                        role="tab"
                        className={cn("flex gap-2 items-center text-ui-700", {
                            "cursor-pointer": isSplitView
                        })}>
                        {isSplitView &&
                            <LightCaret
                                className={cn("transition-transform duration-500 ease-in-out w-6 h-6", { "rotate-180": open })}
                            />}
                        <h1 className="text-lg font-normal">
                            {isSplitView ? "Destinations" : "Where do you want to sync your audience?"}
                        </h1>
                        {!open && isSplitView &&
                            <div className="ml-auto text-ui-400 flex gap-2">
                                {selectedDestinations && Object.keys(selectedDestinations)?.map(d => <AccountIcon service={d as Destination} key={d} />)}
                            </div>}
                    </div>
                </Collapsible.Trigger>
                <Collapsible.Content>
                    <div className={cn("flex gap-1.5 mt-6", {
                        "flex-row group-[.sm-panel]/panel:flex-col": isSplitView
                    })}>
                        {Object.keys(AD_DESTINATIONS).map(destination => {
                            // @ts-expect-error fix the type later
                            const { name, helpText } = AD_DESTINATIONS[destination];
                            const connection = connectionsHash[destination as Destination];

                            return (
                                <DestinationItem
                                    loading={loading || connectionsLoading}
                                    disabled={disabled}
                                    key={destination}
                                    name={name}
                                    service={destination as Destination}
                                    helpText={helpText}
                                    // @ts-expect-error fix the type later
                                    selected={selectedDestinations[destination] ?? false}
                                    connected={!!connection}
                                    error={connection?.error}
                                    onSelectionChange={destinationChange}
                                />
                            );
                        })}
                    </div>
                    {!loading && <EnrichmentBanner className="mt-2" />}
                </Collapsible.Content>
            </Collapsible.Root>
        </Card>
    );
};
