import { formatStripeAmount } from "@/utils/number";
import { Button } from "@/components/atoms/Button/Button";

interface Props {
    amount: number;
    isYearly: boolean;
    audienceLimit: number;
}

export const CurrentPlanCard = ({ amount, isYearly, audienceLimit }: Props) => {
    return (
        <div className="w-[320px] flex flex-col gap-4 px-8 py-6 border rounded-2xl border-blue-200 z-50">
            <div className="flex items-center py-[9px] px-2.5  bg-ui-300/[0.19] rounded-lg text-ui-900 text-12 w-max">
                <div>{`Billed: ${isYearly ? "Annually" : "Monthly"}`}</div>
            </div>

            <div className="flex gap-2 items-center opacity-50">
                <span className="text-ui-900 text-[48px] leading-[57px]">{audienceLimit}</span>
                <span className="text-12 text-ui-700">
                    active <br /> audiences
                </span>
            </div>

            <div className="opacity-50">
                <div className="text-10 font-medium text-transparent">_</div>
                <div className="flex gap-2 items-center">
                    <span className="text-ui-900 text-[48px] leading-[57px]">
                        {formatStripeAmount(isYearly ? amount / 12 / 100 : amount / 100, 0)}
                    </span>
                    <div className="flex flex-col justify-center">
                        <span className="text-12 text-ui-700">per month</span>
                    </div>
                </div>
            </div>

            <Button className="!text-green-500 !bg-green-100">Your Current Plan</Button>
        </div>
    );
};
