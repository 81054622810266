import { GetFirstPartyFiltersResponse } from "@/types/api";
import { filterOperatorConfigs } from "@primer/filters/configs";
import {
    Filter,
    FilterCategory,
    FilterConfig,
    FilterDataTypes,
    FilterEntityTypes,
    FilterFields,
    FilterOperators,
    OperatorConfig,
} from "@primer/filters/types";
import { isBefore } from "date-fns";
import { filter, isEmpty, isEqual, isUndefined, omitBy, pick } from "lodash";

export const getOperatorName = (filter?: FilterFields | string, operatorConfig?: OperatorConfig): string => {
    return (
        (filter && operatorConfig?.customName?.[filter]
            ? operatorConfig?.customName?.[filter]
            : operatorConfig?.name) ?? ""
    );
};

export const findOperatorName = (operator: FilterOperators): string => {
    return filterOperatorConfigs[operator]?.name ?? operator;
};

export const getCustomError = (filter: Partial<Filter>): string | undefined => {
    // Validate year field
    if (filter?.field === FilterFields.FOUNDED_YEAR) {
        if (filter.values?.some(({ value }) => !/^[1-9][0-9]*$/.test(value.toString()))) {
            return "invalid value format";
        }

        const currentYear = new Date().getFullYear();
        if (
            filter.values?.some(({ value }) => {
                const numericValue = parseInt(value.toString());
                return numericValue < 1000 || numericValue > currentYear;
            })
        ) {
            return "year entry outside acceptable range";
        }
    }

    // Validate range fields, max is greater than min
    if (filter?.operator === FilterOperators.BETWEEN || filter?.operator === FilterOperators.NOT_BETWEEN) {
        const MIN_EXCEEDS_MAX_MSG = "minimum values exceeds maximum value";

        const value1 = filter.values?.[0]?.value;
        const value2 = filter.values?.[1]?.value;

        // Number range
        if (typeof value1 === "number" && typeof value2 === "number" && value1 >= value2) {
            return MIN_EXCEEDS_MAX_MSG;
        }

        // date/date-time field
        if (
            filter.dataType &&
            [FilterDataTypes.DATE_TIME, FilterDataTypes.DATE].includes(filter.dataType) &&
            value1 &&
            value2 &&
            isBefore(new Date(value2 as string), new Date(value1 as string))
        ) {
            return MIN_EXCEEDS_MAX_MSG;
        }
    }

    return;
};

const isDuplicate = (filters: Partial<Filter>[], filter: Partial<Filter>) => {
    const duplicates = filters.filter(
        f =>
            f.field === filter.field &&
            f.operator === filter.operator &&
            filter.objectType === f.objectType &&
            filter.path === f.path &&
            f.operator !== FilterOperators.CONTAINS &&
            f.operator !== FilterOperators.NOT_BETWEEN,
    );

    return duplicates.findIndex(f => f.unique_id === filter.unique_id) > 0;
};

export const validateFilter = (filters: Partial<Filter>[], filter: Partial<Filter>) => {
    const errors: {
        isEmpty?: boolean;
        isDuplicated?: boolean;
        customError?: string;
    } = {
        isEmpty: false,
        isDuplicated: false,
        customError: undefined,
    };

    const isRelativeDateRange =
        [FilterDataTypes.DATE, FilterDataTypes.DATE_TIME].includes(filter.dataType!) &&
        [FilterOperators.IS, FilterOperators.IS_NOT].includes(filter.operator!);

    const operatorConfig = filter.operator ? filterOperatorConfigs[filter.operator] : null;
    if (operatorConfig?.numberOfParams !== 0) {
        const validValues = filter.values?.filter(
            v => !!v && v.value !== "" && v.value !== undefined && v.invalid !== true && v.exceeded !== true,
        );

        const minNumberOfValues = operatorConfig?.numberOfParams ?? 1;
        if (!validValues?.length || validValues.length < minNumberOfValues) errors.isEmpty = true;
    }
    if (!filter.unique_id || isDuplicate(filters, filter)) errors.isDuplicated = true;

    if (isRelativeDateRange) {
        errors.isEmpty = isEmpty(filter.values?.[0]?.value);
    }

    errors.customError = getCustomError(filter);

    return errors;
};

export const filterChanged = (before?: any[], after?: any[]) => {
    const comparableProperties: string[] = ["field", "operator", "values", "objectType"];
    const cleanCopy = (array?: any[]) =>
        filter(
            array?.map(item => omitBy(pick(item, comparableProperties), isUndefined)),
            obj => !isEmpty(obj),
        );

    return !isEqual(cleanCopy(before), cleanCopy(after));
};

export const hasFirstPartyFilters = (filters: Partial<Filter>[]) => filters.some(f => !!f.instanceId);

export const mapById = (firstPartyFields: GetFirstPartyFiltersResponse) => {
    const mappingFunction = (field: any) => {
        const { id, name, metadata, ...rest } = field;
        return {
            ...rest,
            displayName: name || id,
            allowCustomInput: true,
            categories: [FilterCategory.SALESFORCE],
            entityType: FilterEntityTypes.PERSON,
            identifier: id,
            name: name || id,
            tooltip: "",
            picklistValues: metadata?.picklist,
        };
    };

    return {
        "lead": filter(firstPartyFields, { objectType: "lead" }).reduce(
            (obj, item) => {
                obj[item.id] = mappingFunction(item);
                return obj;
            },
            {} as { [key: string]: FilterConfig },
        ),
        "account": filter(firstPartyFields, { objectType: "account" }).reduce(
            (obj, item) => {
                obj[item.id] = mappingFunction(item);
                return obj;
            },
            {} as { [key: string]: FilterConfig },
        ),
        "contact": filter(firstPartyFields, { objectType: "contact" }).reduce(
            (obj, item) => {
                obj[item.id] = mappingFunction(item);
                return obj;
            },
            {} as { [key: string]: FilterConfig },
        ),
    };
};
