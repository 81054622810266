import { useEffect, useState } from "react";
import { usePostHog } from "posthog-js/react";

export enum FeatureFlagsEnum {
    ADD_PREFIX_TO_AUDIENCE_NAME = "add-prefix-to-audience-name",
    PAYMENT_PITCH = "pitch-9-2-12-pricing",
    FIRST_PARTY_FILTERS = "pitch-9-1-22-1st-party",
    SALESFORCE_ENABLED = "pitch-9-1-16-Integrating-sfdc-with-primer2",
    QUICK_SUMMARY = "gt-1464-quick-summary", 
    SUMMARY = "pitch-summary-shipped",
    PREVIEW = "pitch-preview-shipped",
    ONBOARDING_PITCH = "pitch-10-2-onboarding",
    CSV_IMPORT = "pitch-9-2-19-csv-import",
}

export const useFeatureFlag = (flag: string, defaultEnabled: boolean = false) => {
    const posthog = usePostHog();

    const [featureEnabled, setFeatureEnabled] = useState<boolean | undefined>(posthog.isFeatureEnabled(flag));

    useEffect(() => {
        return posthog.onFeatureFlags(() => {
            setFeatureEnabled(posthog.isFeatureEnabled(flag));
        });
    }, [posthog, flag]);

    if (featureEnabled === undefined) return defaultEnabled;
    return featureEnabled;
};
