/* eslint-disable react-hooks/exhaustive-deps */
import { toLower } from "lodash";
import React, { KeyboardEventHandler, useCallback } from "react";

import {
    Filter,
    FilterConfig,
    FilterDataTypes,
    FilterFields,
    FilterOperators,
    OperatorConfig,
    RelativeDateRangeOperator,
    RelativeDateRangeUnit,
    SourceCriteriaFilterValue,
} from "@primer/filters/types";
import { parseValue } from "@/utils/string";
import Radio from "@/components/atoms/Radio/Radio";
import Select from "@/components/atoms/Select/Select";
import { Input } from "@/components/atoms/Input/Input";
import { NumberInput } from "@/components/atoms/NumberInput/NumberInput";
import FilterMultiSelect from "@/components/organisms/FilterMultiSelect/FilterMultiSelect";
import posthog from "posthog-js";

interface FilterCriteriaValuesProps {
    dropdownOpen: boolean;
    filter: Partial<Filter>;
    newFilter: Partial<Filter>;
    selectedFilterField: FilterConfig;
    selectedOperator?: OperatorConfig;
    selectedValues: SourceCriteriaFilterValue[];

    updateEditableField: (filterId: string, readOnly: boolean) => void;
    setSelectedValues: React.Dispatch<React.SetStateAction<SourceCriteriaFilterValue[] | undefined>>;
    setNewFilter: (value: Partial<Filter>) => void;
    setDropdownOpen: React.Dispatch<React.SetStateAction<boolean>>;
    setIsValidating: React.Dispatch<React.SetStateAction<boolean>>;
}

export const FilterCriteriaValues = ({
    filter,
    selectedFilterField,
    selectedOperator,
    selectedValues,
    newFilter,
    dropdownOpen,
    updateEditableField,
    setSelectedValues,
    setNewFilter,
    setDropdownOpen,
    setIsValidating,
}: FilterCriteriaValuesProps) => {
    const needsRangeSelect = !!selectedOperator?.numberOfParams;
    const isMultiSelect =
        selectedOperator?.numberOfParams === undefined &&
        [FilterDataTypes.STRING, FilterDataTypes.ARRAY, FilterDataTypes.LOCATION].includes(
            selectedFilterField.dataType,
        );
    const isSingleDateInput = !needsRangeSelect && selectedFilterField.dataType === FilterDataTypes.DATE;
    const isSingleDateTimeInput = !needsRangeSelect && selectedFilterField.dataType === FilterDataTypes.DATE_TIME;
    const isNumberInput = selectedFilterField.dataType === FilterDataTypes.NUMBER;
    const isSingleNumberInput = !needsRangeSelect && isNumberInput;
    const isBooleanInput = selectedFilterField.dataType === FilterDataTypes.BOOLEAN;
    const isCSVInput = selectedFilterField.dataType === FilterDataTypes.CSV;

    const isRelativeDateRangeOperator =
        (isSingleDateInput || isSingleDateTimeInput) &&
        (selectedOperator?.id === FilterOperators.IS || selectedOperator?.id === FilterOperators.IS_NOT);

    const handleKeyDown =
        (index: number): KeyboardEventHandler =>
        event => {
            if (selectedOperator && event.key === "Enter" && filter?.unique_id) {
                const numberOfFields = selectedOperator?.numberOfParams ?? 0;
                if (numberOfFields > 1 && index < numberOfFields - 1) return;

                updateFilterValues();
                updateEditableField(filter.unique_id, false);
            }
        };

    const updateFilterValues = React.useCallback(() => {
        setNewFilter({
            ...newFilter,
            values: selectedValues,
        });
    }, [selectedValues]);

    const handleNumberRangeValueChange = (eventValue: string, index: number, numberOfParamsOverride?: number): void => {
        if (
            selectedFilterField.dataType === FilterDataTypes.NUMBER &&
            eventValue !== "" &&
            !/^[0-9\b]+$/.test(eventValue)
        )
            return;

        const newValue =
            selectedFilterField.dataType === FilterDataTypes.NUMBER && eventValue
                ? parseInt(eventValue)
                : toLower(eventValue);
        setSelectedValues((prevValue: SourceCriteriaFilterValue[] | undefined) => {
            let updatedValues = prevValue?.slice() ?? [];

            const expectedSize = numberOfParamsOverride ?? selectedOperator?.numberOfParams ?? 1;
            if (updatedValues.length < expectedSize) {
                while (updatedValues.length < expectedSize) {
                    updatedValues.push({ value: "" });
                }
            } else if (updatedValues.length > expectedSize) {
                updatedValues = updatedValues.slice(0, expectedSize);
            }

            if (index >= 0 && index < expectedSize) {
                updatedValues[index] = {
                    value: newValue,
                    label: eventValue,
                    invalid: false,
                };
            }
            return updatedValues;
        });
    };

    const dateTimeInput = useCallback(
        (type: "datetime-local" | "date", valueIndex?: number) => {
            if (!isRelativeDateRangeOperator) {
                return (
                    <Input
                        className="w-full h-[36px]"
                        type={type}
                        onChange={e => {
                            const value = e.target.value as string;
                            const newValueObj = {
                                value,
                                invalid: false,
                                label: value,
                            };

                            let newSelectedValues: SourceCriteriaFilterValue[] = [newValueObj];

                            if (valueIndex !== undefined) {
                                newSelectedValues = [...selectedValues];
                                newSelectedValues[valueIndex] = newValueObj;
                            }

                            setSelectedValues(newSelectedValues);
                        }}
                        value={(selectedValues[valueIndex ?? 0]?.value as string) ?? ""}
                    />
                );
            }

            return (
                <>
                    <Select
                        fullWidth={false}
                        value={(selectedValues[1]?.value as string) ?? RelativeDateRangeOperator.EXACT_DATE}
                        onValueChange={(value: RelativeDateRangeOperator) => {
                            const newValue = {
                                value,
                                label: String(value),
                                invalid: false,
                            };
                            const isExactDate = value === RelativeDateRangeOperator.EXACT_DATE;
                            const newSelectedValues = isExactDate ? Array(2) : Array(3);

                            if (!isExactDate) {
                                posthog.capture("Used timeframe operator");
                            }

                            newSelectedValues[1] = newValue;

                            newSelectedValues[0] = {
                                value: "",
                                label: "",
                                invalid: false,
                            };

                            if (!isExactDate) {
                                newSelectedValues[2] = {
                                    value: RelativeDateRangeUnit.DAYS,
                                    label: String(RelativeDateRangeUnit.DAYS),
                                    invalid: false,
                                };
                            }
                            setSelectedValues(newSelectedValues);
                        }}
                        items={[
                            {
                                value: RelativeDateRangeOperator.EXACT_DATE,
                                description: "exact date",
                            },
                            {
                                value: RelativeDateRangeOperator.WITHIN_LAST,
                                description: "within last",
                            },
                        ]}
                    />

                    {(!selectedValues?.[1] || selectedValues?.[1]?.value === RelativeDateRangeOperator.EXACT_DATE) && (
                        <Input
                            className="w-full h-[36px]"
                            type={type}
                            onChange={e => {
                                const value = e.target.value as string;
                                const newValueObj = {
                                    value,
                                    invalid: false,
                                    label: value,
                                };

                                let newSelectedValues: SourceCriteriaFilterValue[] = [newValueObj];

                                if (valueIndex !== undefined) {
                                    newSelectedValues = [...selectedValues];
                                    newSelectedValues[valueIndex] = newValueObj;
                                }

                                newSelectedValues.push({
                                    value: RelativeDateRangeOperator.EXACT_DATE,
                                    label: String(RelativeDateRangeOperator.EXACT_DATE),
                                    invalid: false,
                                });

                                setSelectedValues(newSelectedValues);
                            }}
                            value={(selectedValues[valueIndex ?? 0]?.value as string) ?? ""}
                        />
                    )}
                    {selectedValues?.[1]?.value &&
                        selectedValues?.[1]?.value !== RelativeDateRangeOperator.EXACT_DATE && (
                            <>
                                <NumberInput
                                    placeholder={"Number"}
                                    onKeyDown={handleKeyDown(0)}
                                    value={parseValue(selectedValues?.[0]?.value as string)}
                                    onChange={eventValue => handleNumberRangeValueChange(eventValue, 0, 3)}
                                    containerClassName={"w-40"}
                                    className="w-40"
                                    addCommas={true}
                                />
                                <Select
                                    fullWidth={false}
                                    value={(selectedValues[2]?.value as string) ?? RelativeDateRangeUnit.DAYS}
                                    onValueChange={(value: RelativeDateRangeUnit) => {
                                        const newSelectedValues = [...selectedValues];
                                        newSelectedValues[2] = {
                                            value,
                                            label: String(value),
                                            invalid: false,
                                        };
                                        setSelectedValues(newSelectedValues);
                                    }}
                                    items={[
                                        {
                                            value: RelativeDateRangeUnit.DAYS,
                                            description: "days",
                                        },
                                        {
                                            value: RelativeDateRangeUnit.WEEKS,
                                            description: "weeks",
                                        },
                                        { value: RelativeDateRangeUnit.MONTHS, description: "months" },
                                        { value: RelativeDateRangeUnit.YEARS, description: "years" },
                                    ]}
                                />
                            </>
                        )}
                </>
            );
        },
        [selectedValues, setSelectedValues, isRelativeDateRangeOperator],
    );

    // These operators don't need a value
    if (
        selectedOperator &&
        [
            FilterOperators.IS_KNOWN,
            FilterOperators.IS_UNKNOWN,
            FilterOperators.IS_WITHIN,
            FilterOperators.EXCLUDE,
        ].includes(selectedOperator.id)
    ) {
        return null;
    }

    return (
        <>
            {selectedFilterField && (
                <div className="w-full flex gap-2">
                    {isSingleNumberInput && (
                        <NumberInput
                            {...selectedFilterField.props}
                            placeholder={"Type in value"}
                            onKeyDown={handleKeyDown(0)}
                            value={parseValue(selectedValues?.[0]?.value as string)}
                            onChange={eventValue => handleNumberRangeValueChange(eventValue, 0)}
                            containerClassName={"w-full"}
                            className="w-full"
                            addCommas={selectedFilterField?.identifier !== FilterFields.FOUNDED_YEAR}
                        />
                    )}
                    {isCSVInput && <Select items={[{ value: "replace", description: "Replace CSV file" }]} />}
                    {isSingleDateInput && dateTimeInput("date")}
                    {isSingleDateTimeInput && dateTimeInput("datetime-local")}
                    {isMultiSelect && (
                        <FilterMultiSelect
                            closeEditMode={() => updateEditableField(filter.unique_id!, false)}
                            selectedValues={selectedValues}
                            setSelectedValues={setSelectedValues}
                            selectedFilterField={selectedFilterField}
                            selectedOperator={selectedOperator}
                            dropdownOpen={dropdownOpen}
                            setIsValidating={setIsValidating}
                            setDropdownOpen={setDropdownOpen}
                        />
                    )}
                    {needsRangeSelect &&
                        Array.from({ length: selectedOperator.numberOfParams ?? 1 }).map((_, index) => {
                            const current = selectedValues?.[index];

                            if (isNumberInput) {
                                return (
                                    <NumberInput
                                        {...selectedFilterField.props}
                                        key={selectedOperator.name + index}
                                        placeholder={selectedOperator.placeholders[index]}
                                        onKeyDown={handleKeyDown(index)}
                                        value={parseValue(current?.value)}
                                        onChange={eventValue => handleNumberRangeValueChange(eventValue, index)}
                                        containerClassName={
                                            !selectedOperator.numberOfParams || selectedOperator.numberOfParams === 1
                                                ? "w-full"
                                                : "w-1/" + selectedOperator.numberOfParams
                                        }
                                        className="w-full"
                                        addCommas={selectedFilterField?.identifier !== FilterFields.FOUNDED_YEAR}
                                    />
                                );
                            } else if (selectedFilterField.dataType === FilterDataTypes.DATE) {
                                return dateTimeInput("date", index);
                            } else if (selectedFilterField.dataType === FilterDataTypes.DATE_TIME) {
                                return dateTimeInput("datetime-local", index);
                            }
                        })}
                    {isBooleanInput && (
                        <Radio
                            className="min-w-fit w-fit flex gap-2 h-[36px]"
                            items={[
                                {
                                    value: "true",
                                    description: "True",
                                },
                                {
                                    value: "false",
                                    description: "False",
                                },
                            ]}
                            value={(selectedValues[0]?.value as string) ?? undefined}
                            name={selectedFilterField.identifier}
                            onValueChange={(value: string) => {
                                setSelectedValues([
                                    {
                                        value,
                                        invalid: false,
                                        label: value === "true" ? "True" : "False",
                                    },
                                ]);
                            }}
                        />
                    )}
                </div>
            )}
        </>
    );
};
