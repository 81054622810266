import EnrichmentIcon from "@/assets/icons/enrichment.svg?react";
import cn from "classnames";
import { AccessibleIcon } from "@radix-ui/react-accessible-icon";

export interface EnrichmentBannerProps {
    className?: string;
}

const EnrichmentBanner = ({ className }: EnrichmentBannerProps) => {
    return (
        <div className={cn("flex bg-green-legacy-light p-3 rounded-lg", className)}>
            <AccessibleIcon label="Enrichment">
                <EnrichmentIcon className="h-5 w-5 mr-2" />
            </AccessibleIcon>

            <p className="text-sm text-ui-700">
                We automatically enrich your audience to boost match rates as high as possible.
                <a
                    href="https://support.sayprimer.com/primer/building-audiences/ad-audience-enrichments"
                    target="_blank"
                    className="ml-1 text-blue-800"
                >
                    Learn more.
                </a>
            </p>
        </div>
    );
};

export default EnrichmentBanner;
