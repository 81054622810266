import { useEffect, useState } from "react";

const ProgressLoader = () => {
    const [position, setPosition] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setPosition(prev => (prev + 2) % 600);
        }, 8);
        return () => clearInterval(interval);
    }, []);

    return (
        <div className="relative w-[400px] h-2 bg-ui-300/[0.19] overflow-hidden rounded-lg">
            <div
                className="absolute top-0 rounded-lg w-40 h-full bg-blue-800"
                style={{ left: `${position - 200}px` }}
            ></div>
        </div>
    );
};

export default ProgressLoader;
