import { SummaryStateResponse } from "@/hooks/useSummary";
import SummaryBox from "@/components/organisms/SummaryBox/SummaryBox";
import React, { ReactElement } from "react";
import { random } from "lodash";
import Skeleton from "@/components/atoms/Skeleton/Skeleton";
import { ExclusionHookResponse } from "@/hooks/useExclusionStateManager";

interface SummaryCardProps {
    summaryState: SummaryStateResponse;
    isSummaryLoading?: boolean;
    isExpanding?: boolean;
    expand: () => void;
    exclusionStateManager: ExclusionHookResponse;
}

export const SummaryCard = ({
    summaryState,
    isSummaryLoading,
    isExpanding,
    expand,
    exclusionStateManager,
}: SummaryCardProps) => {
    const [loadingSkeletons, setLoadingSkeletons] = React.useState<ReactElement[][]>([]);

    React.useEffect(() => updateLoadingSkeletons(), []);
    const updateLoadingSkeletons = () => {
        const skeletons = Array.from({ length: 7 }).map(() => {
            const skeletonElements: ReactElement[] = [];

            for (let i = 0; i < 8; i++) {
                const widthName = random(15, 40) * 5;
                const widthPercentage = random(9, 10) * 4;

                skeletonElements.push(
                    <div className="flex h-[32px] items-center" key={i}>
                        <Skeleton className={`h-4 rounded-xl bg-gray-300`} style={{ width: `${widthName}px` }} />
                        <Skeleton
                            className={`h-4 rounded-xl ml-auto bg-gray-300`}
                            style={{ width: `${widthPercentage}px` }}
                        />
                        <Skeleton
                            className={`h-4 ml-3 mr-2 rounded-xl w-4 bg-gray-300`}
                        />
                    </div>,
                );
            }
            return skeletonElements;
        })
        setLoadingSkeletons(skeletons);
    };

    return (
        <div className="p-[16px] px-[8px] grid grid-cols-3 group-[.xs-panel]/panel:grid-cols-1 group-[.sm-panel]/panel:grid-cols-2 group-[.md-panel]/panel:grid-cols-3 group-[.lg-panel]/panel:grid-cols-4 group-[.xl-panel]/panel:grid-cols-5 gap-8">
            {isSummaryLoading && !isExpanding && !exclusionStateManager.isExcluding && loadingSkeletons.map((l, index) =>
                <div key={`loading-${index}`}>
                    <div className="mb-[8px] font-bold uppercase text-xs text-ui-700">
                        <Skeleton className="w-24 h-4 rounded-xl" />
                    </div>
                    <div className="border b-1 rounded-md border-ui-100 p-[4px] h-[278px] overflow-hidden pr-[12px]">
                        <div className="w-full flex flex-col gap-[2px]">
                            {l}
                        </div>
                    </div>
                </div>)}
            {(!isSummaryLoading || isExpanding || exclusionStateManager.isExcluding) && summaryState.summaryValues?.map((summary, i) => (
                <div key={i} className="static">
                    <SummaryBox
                        allowExpand={summaryState.allowExpand}
                        isExpanding={isExpanding}
                        expand={expand}
                        summary={summary}
                        exclusionStateManager={exclusionStateManager} />
                </div>))}
        </div>
    );
};
