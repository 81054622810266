import { useEffect, useState } from "react";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";

import { Modal } from "@/components/organisms/Modal";
import { Button } from "@/components/atoms/Button/Button";
import { useToast } from "@/components/atoms/Toast/useToast";
import { useUpgradeSubscriptionMutation } from "@/api/users";
import { PaymentMethod, SubscriptionProducts, SubscriptionUpdateItem } from "@/types/subscription";
import { StripePaymentMethod } from "@/components/organisms/subscription/PaymentMethod/StripePaymentMethod";
import postHog from "posthog-js";

interface Props {
    currentSubscriptionItemId: string;
    paymentMethod: PaymentMethod;
    frequency: "yearly" | "monthly";
    currentAddAudienceSubscriptionItemId: string;
    close: (closePlans?: boolean) => void;
    subscriptionProducts: SubscriptionProducts;
}

export const CheckoutModal = ({
    paymentMethod,
    subscriptionProducts,
    currentAddAudienceSubscriptionItemId,
    currentSubscriptionItemId,
    frequency,
    close,
}: Props) => {
    const { toast } = useToast();

    const [editing, setEditing] = useState(false);

    const { card, last4 } = paymentMethod;
    const paymentAdded = !!Object.keys(paymentMethod).length;

    const closePayment = !paymentAdded && !editing ? () => close() : () => setEditing(false);

    const isYearly = frequency === "yearly";
    const quantity = subscriptionProducts.grow.tier2[frequency].audience_limit;

    const updateItems: SubscriptionUpdateItem[] = [
        { id: currentSubscriptionItemId, quantity: 1, price: subscriptionProducts.grow.tier2[frequency].id },
        {
            quantity,
            id: currentAddAudienceSubscriptionItemId,
            price: subscriptionProducts.audiences[frequency].id,
        },
    ];

    const [upgradeSubscription, { isLoading, error: updateSubscriptionError }] = useUpgradeSubscriptionMutation();

    const handleSubmit = async () => {
        await upgradeSubscription(updateItems);

        postHog.capture(`Subscription plan upgraded`, {
            ...updateItems,
        });

        toast({
            variant: "default",
            description: "Subscription updated successfully",
        });

        close(true);
    };

    useEffect(() => {
        if (!updateSubscriptionError) return;
        const fetchBaseQueryError = updateSubscriptionError as FetchBaseQueryError;

        toast({
            variant: "destructive",
            description: (fetchBaseQueryError.data as string) || "An error occurred. Please retry",
        });
    }, [toast, updateSubscriptionError]);

    return (
        <Modal className="w-full h-full rounded-none !bg-dark-200 overflow-auto" zIndex="z-50">
            <div className="mx-auto my-auto w-[500px] flex flex-col items-center gap-6 text-ui-700 ">
                <h1 className="text-h1">Upgrade Plan</h1>

                <div className="w-full bg-white px-8 py-6 pt-4 rounded-2xl shadow-main">
                    <div className="mb-4 space-y-4">
                        <h2 className="text-h2 text-ui-900">Order Details</h2>
                        <div className="flex justify-between text-16">
                            <div>Active Audiences</div>
                            <div>{quantity}</div>
                        </div>

                        {isYearly && (
                            <div className="flex justify-between text-16">
                                <div>Subtotal</div>
                                <div>$18,000</div>
                            </div>
                        )}

                        {isYearly && (
                            <div className="flex justify-between text-16">
                                <div>Annual plan saving</div>
                                <div className="text-green-500">-$3,000</div>
                            </div>
                        )}

                        <div className="h-[1px] bg-ui-200"></div>

                        <div className="flex justify-between items-center">
                            <div className="text-16">Total Billed</div>
                            <div className="flex flex-col gap-1 items-end">
                                <div className="text-h1 text-ui-900">{isYearly ? "$15,000" : "$1,500"}</div>
                                <div className="text-12 text-ui-300">Plus all applicable taxes</div>
                            </div>
                        </div>
                    </div>

                    {/* Payment  */}
                    <div className="w-full flex flex-col rounded-2xl bg-white">
                        <h2 className="text-h2 text-ui-900 mb-4">Payment Information</h2>

                        {editing ? (
                            <StripePaymentMethod close={closePayment} paymentMethod={paymentMethod} />
                        ) : paymentAdded ? (
                            <div className="flex gap-4 items-center p-4 border rounded-md border-ui-200">
                                <div className="w-12 h-8">
                                    <img alt={card} title={card} src={`cards/${card}.png`} />
                                </div>

                                <div className="flex-1 truncate">
                                    <div className="text-16 text-ui-900">{`**** **** **** ${last4}`}</div>
                                </div>

                                <Button variant="secondary" onClick={() => setEditing(true)}>
                                    Update
                                </Button>
                            </div>
                        ) : (
                            <StripePaymentMethod
                                close={closePayment}
                                onSuccess={() => {}}
                                paymentMethod={paymentMethod}
                            />
                        )}

                        {!editing && paymentAdded && (
                            <div className="flex justify-between mt-10 w-full">
                                <Button variant="secondary" type="button" onClick={() => close()}>
                                    Cancel
                                </Button>
                                <Button loading={isLoading} onClick={handleSubmit}>
                                    Submit
                                </Button>
                            </div>
                        )}
                    </div>
                </div>

                <div className="w-[500px] mx-auto flex flex-col items-center text-10 text-ui-300 space-y-2">
                    <div>Your payment information is safely encrypted and stored by our partner Stripe.</div>
                    <img alt="Powered by Stripe" title="Powered by Stripe" src="stripe.png" />
                </div>
            </div>
        </Modal>
    );
};
