import React, { KeyboardEventHandler, MouseEventHandler, useMemo, useRef, useState } from "react";
import cn from "classnames";
import { upperFirst } from "lodash";

import { filterConfigs, filterOperatorConfigs } from "@primer/filters/configs";
import {
    Filter,
    FilterConfig,
    FilterDataTypes,
    FilterFields,
    FilterOperators,
    RelativeDateRangeOperator,
    SourceCriteriaFilterValue,
} from "@primer/filters/types";

import EditIcon from "@/assets/icons/edit.svg?react";
import CloseIcon from "@/assets/icons/close.svg?react";
import CarotUpIcon from "@/assets/icons/carot-up.svg?react";
import CarotDownIcon from "@/assets/icons/carot-down.svg?react";
import ErrorIcon from "@/assets/icons/error-red.svg?react";
import CsvFileIcon from "@/assets/icons/csv.svg?react";
import { Button } from "@/components/atoms/Button/Button";
import { Tooltip } from "@/components/atoms/Tooltip/Tooltip";
import AccountIcon from "@/components/molecules/AccountIcon/AccountIcon";
import { useIsElementOverflowing } from "@/hooks/useIsElementOverflowing";
import { Destination } from "@/types/audience";
import { parseFilterName } from "@/utils/string";
import { getOperatorName } from "@/utils/filter";
import { FeatureFlagsEnum, useFeatureFlag } from "@/hooks";

interface FilterCriteriaViewerProps {
    index: number;
    filter: Partial<Filter>;
    filterDisplayName?: string;
    disabled: boolean;
    updateEditableField: (filterId: string, readOnly: boolean) => void;
    onRemoveFilter?: MouseEventHandler;
}

export const FilterCriteriaViewer = ({
    index,
    filter,
    filterDisplayName,
    disabled,
    updateEditableField,
    onRemoveFilter,
}: FilterCriteriaViewerProps) => {
    const isSummaryEnabled = useFeatureFlag(FeatureFlagsEnum.SUMMARY);
    const isPreviewEnabled = useFeatureFlag(FeatureFlagsEnum.PREVIEW);
    const isSplitView = isSummaryEnabled || isPreviewEnabled;

    const componentRef = useRef<HTMLDivElement>(null);
    const isFirstParty = Boolean(filter.instanceId);
    const isCsv = Boolean(filter.mappingTable);

    const selectedFilterField: Partial<FilterConfig> | undefined = useMemo(() => {
        if (isFirstParty) {
            return {
                ...filter,
                displayName: filterDisplayName,
            };
        }

        if (isCsv) {
            return {
                ...filter,
                displayName: filter.field,
            };
        }

        if (filter?.field) return filterConfigs[filter.field];
    }, [filter, filterDisplayName, isFirstParty, isCsv]);

    const selectedOperator = !filter?.operator ? undefined : filterOperatorConfigs[filter.operator];

    const isInvalid = filter.isDuplicated || filter.isEmpty || !!filter.customError;

    const visibleFieldValuesRef = useRef<HTMLDivElement | null>(null);
    const isVisibleFieldValuesOverflowing = useIsElementOverflowing(visibleFieldValuesRef, [filter.values]);

    const [isVisibleFieldValuesExpanded, setIsVisibleFieldValuesExpanded] = useState(false);

    const formatValue = (filterValue: SourceCriteriaFilterValue) => {
        if (
            selectedFilterField?.dataType === FilterDataTypes.NUMBER &&
            selectedFilterField.identifier !== FilterFields.FOUNDED_YEAR
        ) {
            const prefix = selectedFilterField.identifier === FilterFields.ANNUAL_REVENUE ? "$" : "";
            return prefix + filterValue.value?.toLocaleString("en-US", { maximumFractionDigits: 0 });
        }

        return parseFilterName(selectedFilterField, filterValue);
    };

    const getJoinStyle = () => {
        if (selectedOperator?.join) return <span className="text-ui-300"> {selectedOperator?.join} </span>;

        return `${selectedFilterField?.join ?? ","} `;
    };

    const valuesDisplay = useMemo(() => {
        const isRelativeDateRange =
            [FilterDataTypes.DATE, FilterDataTypes.DATE_TIME].includes(filter.dataType!) &&
            [FilterOperators.IS, FilterOperators.IS_NOT].includes(filter.operator!);
        const isExactMatchOperator = filter.values?.[1]?.value === RelativeDateRangeOperator.EXACT_DATE;

        if (isRelativeDateRange && filter.values?.length && filter.values?.length > 1) {
            const returnArr = [];
            !isExactMatchOperator &&
                returnArr.push(<span>{String(filter.values![1]?.value)?.replace(/_/g, " ")}</span>);
            returnArr.push(" ");
            filter.values?.[0]?.value && returnArr.push(<span>{formatValue(filter.values![0])}</span>);
            returnArr.push(" ");
            !isExactMatchOperator && filter.values![2] && returnArr.push(<span>{formatValue(filter.values![2])}</span>);
            return returnArr;
        }

        return filter.values
            ?.filter(v => v.invalid !== true && v.exceeded !== true)
            ?.map((value, index, array) => (
                <React.Fragment key={index}>
                    <span>{formatValue(value)}</span>
                    {index < array.length - 1 && getJoinStyle()}
                </React.Fragment>
            ));
    }, [filter.values]);

    const getErrorMessage = () => {
        if (filter.customError) return filter.customError;
        if (filter.isDuplicated) return "filter and operator combination already in use";
        if (filter.isEmpty) return "value required";
        return filter.customError;
    };

    const visibleFieldValues = isInvalid ? getErrorMessage() : valuesDisplay;

    const switchToEditMode = () => {
        if (filter.unique_id && filter.field && !disabled) updateEditableField(filter.unique_id, true);
    };

    const handleClick = () => {
        const selection = window.getSelection();

        if (!selection || selection!.toString().length === 0) {
            return switchToEditMode();
        }

        // Check if the selection is within the component
        const component = componentRef.current;
        const range = selection.getRangeAt(0);
        if (component?.contains(range.commonAncestorContainer)) {
            // If there is a selection within the component, do not enter edit mode
            if (selection.toString().length === 0) {
                switchToEditMode();
            }
        }
    };

    const onSelectReadOnlyRow: KeyboardEventHandler = e => {
        if (e.key === "Enter" || e.key === " ") {
            switchToEditMode();
        }
    };

    return (
        <div className="group flex" aria-required={isInvalid} aria-disabled={disabled} aria-readonly={!disabled}>
            <div
                role="tab"
                tabIndex={0}
                id={`filter-${index}`}
                onClick={handleClick}
                onKeyDown={onSelectReadOnlyRow}
                className={`flex gap-2 mb-1 w-full flex gap-[5px] items-start text-sm cursor-pointer p-2 rounded-md max-w-[calc(100%-43px)]
                group-aria-disabled:cursor-default border border-transparent
                group-aria-readonly:hover:bg-blue-50 group-aria-readonly:hover:border-blue-200
                group-aria-required:hover:bg-error-background group-aria-required:hover:border-ui-300/[0.19]
                group-aria-required:bg-error-background group-aria-required:border-ui-300/[0.19]`}
            >
                {isFirstParty && <AccountIcon service={Destination.SALESFORCE} className="min-w-5 min-h-5" />}
                {isInvalid && <div><ErrorIcon className="self-start" /></div>}
                {isFirstParty && (
                    <span className="text-ui-300">
                        {upperFirst(filter.objectType)}
                        {filter.path && ` → ${filter.path}`}.
                    </span>
                )}
                {isCsv && <div><CsvFileIcon className="mr-1" /></div>}
                <span title={selectedFilterField?.displayName ?? selectedFilterField?.name} className={cn("font-medium self-baseline", {
                    "w-fit": !isSplitView,
                    "truncate min-w-[50px]": isCsv,
                    "min-w-fit": !isFirstParty && ((selectedFilterField?.displayName ?? selectedFilterField?.name)?.length ?? 0) < 20,
                })}>
                    {selectedFilterField?.displayName ?? selectedFilterField?.name}
                </span>

                <span className="text-ui-300 min-w-fit self-baseline">
                    {getOperatorName(selectedFilterField?.identifier, selectedOperator)}
                </span>
                <div className="max-w-[70%]">
                    <span className="aria-required:text-error flex" aria-required={isInvalid}>
                        <span
                            className={cn({"line-clamp-3": !isVisibleFieldValuesExpanded })}
                            ref={visibleFieldValuesRef}
                        >
                            {visibleFieldValues}
                        </span>
                        {!disabled && (
                            <Button
                                variant="outline"
                                size="icon-sm"
                                className="ml-[7px] mt-[1px] border-0 px-0 py-0 ml-1 min-w-[20px] min-w-[20px] inline-flex invisible group-hover:visible"
                            >
                                <EditIcon className="text-ui-300" />
                            </Button>
                        )}
                    </span>
                    {(isVisibleFieldValuesOverflowing || isVisibleFieldValuesExpanded) && (
                        <Button
                            onClick={e => {
                                e.stopPropagation();
                                setIsVisibleFieldValuesExpanded(!isVisibleFieldValuesExpanded);
                            }}
                            variant="ghost"
                            className="text-blue-800 text-sm leading-tight !p-0 !h-[unset] mt-1 hover:text-blue-870 hover:underline"
                        >
                            {isVisibleFieldValuesExpanded ? <CarotUpIcon /> : <CarotDownIcon />}
                            {isVisibleFieldValuesExpanded ? "hide values" : `show all ${valuesDisplay?.length} values`}
                        </Button>
                    )}
                </div>
            </div>
            {!disabled && onRemoveFilter && (
                <Tooltip
                    side="bottom"
                    content="Delete Filter"
                    button={{
                        onClick: onRemoveFilter,
                        className: `ml-1 mb-1 border border-transparent rounded-md px-0 py-0
                            flex items-center justify-center w-[30px] h-[40px] invisible
                            group-hover:visible hover:cursor-pointer
                            hover:bg-blue-50 hover:border-blue-200
                            group-aria-required:hover:bg-error-background group-aria-required:hover:border-ui-300/[0.19]`,
                    }}
                >
                    <CloseIcon className="text-ui-300 self-start mt-[13px]" />
                </Tooltip>
            )}
        </div>
    );
};
