import { ChartSchema, ExtraChartSchema, SummaryMetricColumn } from "@/types/audience";
import { sumBy } from "lodash";

export function groupOthers(topValues?: ChartSchema, thresholdPercentage: number = 0.1): ChartSchema | undefined {
    if (!topValues?.values || topValues.values.every(v => v.key !== "Other")) return topValues;

    const threshold = (topValues.total ?? 0) * (thresholdPercentage / 100);

    let otherValue = 0;
    const filteredValues = topValues.values.filter(value => {
        if (value.value < threshold || value.key === "Other") {
            otherValue += value.value;
            return false;
        }
        return true;
    });

    if (otherValue > 0) {
        filteredValues.push({
            label: "Other",
            key: "Other",
            value: otherValue,
        });
    }

    return { ...topValues, values: filteredValues };
}

const parseName = (name?: string): SummaryMetricColumn => {
    switch (name?.toString()) {
        case SummaryMetricColumn.COMPANY_COUNTRY:
        case SummaryMetricColumn.COMPANY_STATE:
        case SummaryMetricColumn.COMPANY_CITY:
            return SummaryMetricColumn.COMPANY_LOCATION;
        default:
            return name as SummaryMetricColumn;
    }
};
const possibleOverflow: string[] = [
    SummaryMetricColumn.JOB_TITLE,
    SummaryMetricColumn.SENIORITY,
    SummaryMetricColumn.INDUSTRY,
    SummaryMetricColumn.COMPANY_CITY,
    SummaryMetricColumn.COMPANY_STATE,
    SummaryMetricColumn.COMPANY_COUNTRY,
    SummaryMetricColumn.COMPANY_LOCATION,
    SummaryMetricColumn.COMPANY_DOMAIN,
];
export function createOthers(topValues: ExtraChartSchema): ExtraChartSchema {
    if (!topValues?.values || !possibleOverflow.includes(topValues?.name ?? "")) return topValues;

    const otherValue = topValues.total - sumBy(topValues.values, "value");
    const filteredValues = topValues.values;

    if (otherValue > 0) {
        const describedPercentage = Math.round(((otherValue * 100) / topValues.total) * 100) / 100;
        filteredValues.push({
            label: "Other",
            key: "Other",
            value: otherValue,
            percentage: describedPercentage,
        });
    }

    return {
        category: topValues.name,
        name: parseName(topValues.name),
        total: topValues.total,
        values: filteredValues,
    };
}
