import { SourceCriteriaFilter as Filter, SummaryMetricColumn } from "@/types/audience";
import { filterConfigs, filterOperatorConfigs } from "@primer/filters/configs";
import { FilterDataTypes, FilterFields, FilterOperators, SourceCriteriaFilterValue } from "@primer/filters/types";
import { v4 as uuidv4 } from "uuid";
import { forEach, minBy, maxBy, map, split, trim, differenceWith, flatMap, isEqual } from "lodash";
import { allowEmpty } from "./audience";
import { SelectedForExclusionItem } from "@/hooks/useExclusionStateManager";

const noMergeOperators = [FilterOperators.CONTAINS, FilterOperators.NOT_CONTAINS];
export const getNewFilterWithExclusions = (newExclusions: SelectedForExclusionItem[], existingFilters: Filter[]) => {
    const newFilters: (Filter | undefined)[] = newExclusions.map(g => {
        const field = parseField(g.category);
        if (!field) return;

        const fieldConfig = filterConfigs[field];
        const operator = parseOperator(field, g);
        const filter: Filter = {
            unique_id: uuidv4(),
            entity_type: fieldConfig.entityType,
            field,
            operator,
            values: parseValues(g.category, field, operator, g),
            isEmpty: false,
            isDuplicated: false,
            dataType: fieldConfig.dataType,
        };

        return filter;
    });
    const validNewFilters = newFilters.filter(f => !!f);
    const validExistingFilters = clearExistingFilterConflicts(validNewFilters, existingFilters);
    const merged = mergeFilters([...(validExistingFilters ?? []), ...validNewFilters]).filter(f => {
        if (!f.operator || f.isEmpty || allowEmpty(f.operator)) return true;
        const config = filterOperatorConfigs[f.operator];

        const minNumberOfValues = config.numberOfParams ?? 1;
        if (!f.values?.length || f.values.length < minNumberOfValues) return false;
        return true;
    });
    return merged;
};

const parseValues = (
    category: string,
    filterField: FilterFields,
    operator: FilterOperators,
    item: SelectedForExclusionItem,
): SourceCriteriaFilterValue[] => {
    const { value, label } = item;
    if (operator === FilterOperators.IS_KNOWN) return [];

    if (filterField === FilterFields.ANNUAL_REVENUE || filterField === FilterFields.HEADCOUNT) {
        return buckets[filterField][value]?.map(value => ({
            label: value.toString(),
            value,
            invalid: false,
        }));
    }

    if (filterField === FilterFields.COMPANY_LOCATION)
        return [
            {
                label: label ?? value,
                value: parseLocationValue(category, value),
                invalid: false,
            },
        ];

    return [
        {
            label: label ?? value,
            value,
            invalid: false,
        },
    ];
};

const parseLocationValue = (category: string, value: string) => {
    const parts = map(split(value, ","), trim);
    let city = "",
        state = "",
        country = "";

    if (category === SummaryMetricColumn.COMPANY_CITY) {
        country = parts.pop() ?? value;
        state = parts.pop() ?? "";
        city = parts.join(", ");
    } else if (category === SummaryMetricColumn.COMPANY_STATE) {
        [state, country] = parts;
    } else if (category === SummaryMetricColumn.COMPANY_COUNTRY) {
        country = value;
    }
    return { country, state, city };
};

const parseOperator = (filterField: FilterFields, item: SelectedForExclusionItem) => {
    const { value } = item;
    if (filterField === FilterFields.ANNUAL_REVENUE || filterField === FilterFields.HEADCOUNT) {
        return value === "0" ? FilterOperators.IS_KNOWN : FilterOperators.NOT_BETWEEN;
    }

    return value === "" ? FilterOperators.IS_KNOWN : FilterOperators.IS_NOT;
};

const parseField = (category: string): FilterFields | undefined => {
    switch (category) {
        case SummaryMetricColumn.JOB_TITLE:
            return FilterFields.JOB_TITLE;
        case SummaryMetricColumn.SENIORITY:
            return FilterFields.SENIORITY;
        case SummaryMetricColumn.COMPANY_DOMAIN:
            return FilterFields.DOMAIN;
        case SummaryMetricColumn.COMPANY_CITY:
        case SummaryMetricColumn.COMPANY_STATE:
        case SummaryMetricColumn.COMPANY_COUNTRY:
        case SummaryMetricColumn.COMPANY_LOCATION:
            return FilterFields.COMPANY_LOCATION;
        case SummaryMetricColumn.ANNUAL_REVENUE:
            return FilterFields.ANNUAL_REVENUE;
        case SummaryMetricColumn.HEADCOUNT:
            return FilterFields.HEADCOUNT;
        case SummaryMetricColumn.INDUSTRY:
            return FilterFields.INDUSTRY;
    }
};

const buckets: {
    [key: string]: {
        [key: string]: number[];
    };
} = {
    [FilterFields.ANNUAL_REVENUE]: {
        "9": [5000000000, 999999999999],
        "8": [500000000, 4999999999],
        "7": [100000000, 499999999],
        "6": [25000000, 99999999],
        "5": [10000000, 24999999],
        "4": [5000000, 9999999],
        "3": [1000000, 4999999],
        "2": [100000, 999999],
        "1": [1, 99999],
        "0": [],
    },
    [FilterFields.HEADCOUNT]: {
        "9": [10001, 1000000],
        "8": [5001, 10000],
        "7": [1001, 5000],
        "6": [501, 1000],
        "5": [201, 500],
        "4": [51, 200],
        "3": [11, 50],
        "2": [2, 10],
        "1": [1, 1],
        "0": [],
    },
};

const isMidToGreater = (siblingMin: number, filterMin: number, filterMax: number) =>
    siblingMin >= filterMin && siblingMin <= filterMax + 1;
const isMidToLess = (siblingMax: number, filterMin: number, filterMax: number) =>
    siblingMax + 1 >= filterMin && siblingMax <= filterMax;
const updateTwins = (twins: Filter[], filter: Filter, siblings: Filter[]): Filter[] => {
    const newTwins: Filter[] = [];
    const filterMin = filter.values?.[0]?.value as number;
    const filterMax = filter.values?.[1]?.value as number;
    for (const sibling of siblings) {
        if (!twins.some(t => t.unique_id === sibling.unique_id)) {
            const siblingMin = sibling.values?.[0]?.value as number;
            const siblingMax = sibling.values?.[1]?.value as number;
            if (isMidToGreater(siblingMin, filterMin, filterMax) || isMidToLess(siblingMax, filterMin, filterMax)) {
                newTwins.push(sibling);
            }
        }
    }

    return newTwins;
};

const getSiblings = (filters: Filter[], filter: Filter) =>
    filters.filter(f => f.field === filter.field && f.operator === filter.operator);

const mergeSimple = (filters: Filter[], filter: Filter, mergedFilters: Filter[]) => {
    const siblings = getSiblings(filters, filter);
    mergedFilters.push({
        ...filter,
        unique_id: siblings.length === 1 ? siblings?.[0]?.unique_id : uuidv4(),
        values: siblings.flatMap(s => s.values).filter(f => !!f),
    });
    return siblings.map(s => s.unique_id);
};

const getNumericRangeTwins = (filters: Filter[], filter: Filter) => {
    const siblings = getSiblings(filters, filter);
    let twins: Filter[] = [filter];
    let newTwins: Filter[] = updateTwins(twins, filter, siblings);
    while (newTwins.length > 0) {
        twins = twins.concat(newTwins);
        newTwins = [];
        twins.forEach(t => {
            newTwins = newTwins.concat(updateTwins(twins, t, siblings));
        });
    }
    return twins;
};

const mergeNumericRanges = (filters: Filter[], filter: Filter, mergedFilters: Filter[]) => {
    const twins = getNumericRangeTwins(filters, filter);
    const min = minBy(
        twins.flatMap(s => s.values),
        v => v?.value,
    );
    const max = maxBy(
        twins.flatMap(s => s.values),
        v => v?.value,
    );
    const values = [min, max].filter(f => !!f);
    mergedFilters.push({
        ...filter,
        unique_id: twins.length === 0 ? twins?.[0]?.unique_id : uuidv4(),
        values,
    });

    return twins.map(t => t.unique_id);
};

const mergeFilters = (filters: Filter[]): Filter[] => {
    let processedFilters: string[] = [];
    const mergedFilters: Filter[] = [];

    forEach(filters, filter => {
        if (!processedFilters.includes(filter.unique_id)) {
            if (
                filter.operator &&
                !noMergeOperators.includes(filter.operator) &&
                (filter.dataType === FilterDataTypes.STRING || filter.dataType === FilterDataTypes.LOCATION)
            ) {
                processedFilters = processedFilters.concat(mergeSimple(filters, filter, mergedFilters));
            } else if (filter.dataType === FilterDataTypes.NUMBER && filter.operator === FilterOperators.NOT_BETWEEN) {
                processedFilters = processedFilters.concat(mergeNumericRanges(filters, filter, mergedFilters));
            } else {
                mergedFilters.push(filter);
                processedFilters.push(filter.unique_id);
            }
        }
    });

    return mergedFilters;
};

const clearExistingFilterConflicts = (validNewFilters?: Filter[], existingFilters?: Filter[]): Filter[] => {
    if (!validNewFilters?.length || !existingFilters?.length) return existingFilters ?? [];

    const updatedFilters = existingFilters.map(existingFilter => {
        if (existingFilter.operator !== FilterOperators.IS) return existingFilter;

        const opposeFilters = validNewFilters.filter(
            v => v.field === existingFilter.field && v.operator === FilterOperators.IS_NOT,
        );
        if (!opposeFilters?.length) return existingFilter;

        const newValues = differenceWith(
            existingFilter.values,
            flatMap(opposeFilters, opFilter => opFilter.values),
            (value1, value2) => value1.value === value2?.value,
        );
        if (isEqual(newValues, existingFilter.values)) return existingFilter;

        const updatedFilter: Filter = {
            ...existingFilter,
            unique_id: uuidv4(),
            values: newValues,
        };
        return updatedFilter;
    });

    return updatedFilters;
};
