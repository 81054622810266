import { Option } from "@/components/organisms/FilterMultiSelect/FilterMultiSelect";
import { hardcodedFilterFieldValuesMap } from "@primer/filters/configs";
import { AddressLocation, FilterConfig, FilterDataTypes, SourceCriteriaFilterValue } from "@primer/filters/types";
import { get } from "lodash";

export const startCaseWords = (str: string) => {
    const symbols = "/ ";
    // Construct a regular expression pattern to match any of the symbols
    const pattern = new RegExp(`([${symbols}])([^${symbols}]*)`, "g");

    // Capitalize the first letter of the string
    const capitalizedString = str.charAt(0).toUpperCase() + str.slice(1);

    // Replace the pattern occurrences while retaining the delimiters
    return capitalizedString.replace(pattern, (_, delimiter, word) => {
        // Capitalize the first character of each word
        const capitalizedWord = word.charAt(0).toUpperCase() + word.slice(1);
        // Return the original delimiter along with the capitalized word
        return delimiter + capitalizedWord;
    });
};

export const parseValue = (value: string | number | AddressLocation): string => {
    if (typeof value === "object") {
        return [value.city, value.state, value.country].filter(Boolean).join("|");
    }

    return value?.toString();
};

export const parseFilterName = (
    selectedFilterField: Partial<FilterConfig> | undefined,
    { label, value }: SourceCriteriaFilterValue | Option,
): string => {
    if (selectedFilterField?.pureValue) return parseValue(value);

    return parseFilterValueLabel(value, label, selectedFilterField?.identifier, selectedFilterField?.dataType);
};

export const parseFilterValueLabel = (
    value: string | number | AddressLocation,
    label?: string,
    category?: string,
    dataType = FilterDataTypes.STRING,
): string => {
    const hardcodedValue =
        dataType === FilterDataTypes.STRING &&
        category &&
        get(hardcodedFilterFieldValuesMap, [category, String(value)]);

    return hardcodedValue || label || startCaseWords(value.toString());
};
