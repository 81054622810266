import { BrandedModal } from "@/components/organisms/Modal/BrandedModal";
import { getPriceInfo } from "@/components/organisms/subscription/utils";
import { ScalePlanCard } from "@/components/organisms/subscription/UpgradePlan/ScalePlanCard";
import { Subscription, SubscriptionInterval, SubscriptionProducts } from "@/types/subscription";
import { CurrentPlanCard } from "@/components/organisms/subscription/UpgradePlan/CurrentPlanCard";
import { GrowUpgradeCard } from "@/components/organisms/subscription/UpgradePlan/GrowUpgradeCard";

interface Props {
    subscription: Subscription;
    subscriptionProducts: SubscriptionProducts;
    closeModal: () => void;
}

export const UpgradePlanModal = ({ subscription, subscriptionProducts, closeModal }: Props) => {
    const { interval, paymentMethod, subscription_items } = subscription;

    const frequency = interval === SubscriptionInterval.YEAR ? "yearly" : "monthly";
    const growPlanTier1Price = getPriceInfo(subscriptionProducts, "grow", "tier1", frequency);
    const growPlanTier2Price = getPriceInfo(subscriptionProducts, "grow", "tier2", frequency);

    const currentSubscriptionItem = subscription_items.find(({ product_id }) =>
        [subscriptionProducts.grow.product_id, subscriptionProducts.scale.product_id].includes(product_id),
    );

    const currentAddAudienceSubscriptionItem = subscription_items.find(
        ({ product_id }) => product_id === subscriptionProducts.audiences.product_id,
    );

    const isPrimerGrowTier1 = currentSubscriptionItem?.plan_id === growPlanTier1Price!.id;

    if (!currentSubscriptionItem || !currentAddAudienceSubscriptionItem) return null;

    return (
        <BrandedModal isLarge={true} className="w-[740px] px-10 py-10" onClose={closeModal}>
            <div className="flex gap-6">
                {isPrimerGrowTier1 ? (
                    <GrowUpgradeCard
                        paymentMethod={paymentMethod}
                        subscriptionProducts={subscriptionProducts}
                        audienceLimit={growPlanTier2Price!.audience_limit}
                        closeUpgradeOptions={closeModal}
                        currentSubscriptionItemId={currentSubscriptionItem.id}
                        currentAddAudienceSubscriptionItemId={currentAddAudienceSubscriptionItem.id}
                    />
                ) : (
                    <CurrentPlanCard
                        amount={currentSubscriptionItem.plan_amount}
                        audienceLimit={currentAddAudienceSubscriptionItem.quantity}
                        isYearly={interval === SubscriptionInterval.YEAR}
                    />
                )}

                <ScalePlanCard buttonVariant={isPrimerGrowTier1 ? "secondary" : "primary"} />
            </div>
        </BrandedModal>
    );
};
