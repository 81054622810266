import { Button } from "@/components/atoms/Button/Button";
import { useUpdateOnboardingMutation } from "@/api/users";
import { useGetConnectionsQuery } from "@/api/integrations";
import InfoIcon from "@/assets/icons/info-outline.svg?react";
import { ConnectionStatus, Service } from "@/types/integrations";
import CrmIcon from "@/assets/images/onboarding/crm-connect.svg";
import ArrowRightIcon from "@/assets/icons/arrow-right.svg?react";
import { getIconForService, getOAuthUrl } from "@/config/integrations";
import { useOnboarding } from "@/components/organisms/onboarding/OnboardingContext";
import { ConnectionStatusPill } from "@/components/organisms/integrations/ConnectionStatus";
import { OnboardingSkeleton, OnboardingWrapper } from "@/components/organisms/onboarding/OnboardingWrapper";

export const CRMConnect = () => {
    const { setCurrentStep, onboarding } = useOnboarding();
    const [updateOnboarding] = useUpdateOnboardingMutation();

    const { data: connections, isLoading } = useGetConnectionsQuery(
        { enabled: true },
        { refetchOnMountOrArgChange: true },
    );
    const icon = getIconForService(Service.SALESFORCE, "h-9 w-9");

    const salesForceConnection = connections?.find(({ provider }) => provider === Service.SALESFORCE);

    const isConnected =
        salesForceConnection?.state === ConnectionStatus.CONNECTED ||
        salesForceConnection?.state === ConnectionStatus.INITIATED;

    const connect = () => {
        const connectUrl = getOAuthUrl(Service.SALESFORCE);
        if (connectUrl) window.location.href = connectUrl;
    };

    const nextStep = async () => {
        isConnected &&
            !onboarding.crm_connected &&
            (await updateOnboarding({ id: onboarding.id, crm_connected: true }));

        setCurrentStep(3);
    };

    const alreadyConnectedByOtherUser = !isLoading && isConnected && !onboarding.crm_connected;

    if (!connections) return <OnboardingSkeleton />;

    return (
        <OnboardingWrapper imgUrl={CrmIcon}>
            <div className="w-full">
                <h1 className="text-h1 mb-2">Connect your CRM</h1>
                <p className="text-16">
                    Primer needs <span className="text-ui-900 font-semibold">read-only</span> access to your CRM to
                    leverage first-party data for audience creation.
                </p>
            </div>

            <div className="flex items-center justify-between px-6 py-4 w-full bg-white border border-ui-200 rounded-lg">
                <div className="flex items-center gap-4">
                    {icon}
                    <div className="text-16">Salesforce</div>
                </div>

                {!isConnected && (
                    <Button
                        onClick={connect}
                        attributes={{ "data-ph-capture-attribute-service-name": Service.SALESFORCE }}
                    >
                        Connect
                    </Button>
                )}

                {isConnected && (
                    <ConnectionStatusPill status={salesForceConnection.state} service={Service.SALESFORCE} />
                )}
            </div>

            {alreadyConnectedByOtherUser && (
                <div className="w-full flex items-center p-3 rounded-lg bg-blue-50 border border-blue-200 space-x-2 text-blue-870 !mt-2">
                    <InfoIcon className="mb-0.5" />
                    <div className="text-14">Already connected for your org by another user.</div>
                </div>
            )}

            {!isConnected && (
                <Button variant="ghost" className="flex items-center gap-0.5 !text-ui-300" onClick={nextStep}>
                    Skip
                    <ArrowRightIcon />
                </Button>
            )}

            {isConnected && (
                <Button className="flex items-center gap-0.5" onClick={nextStep}>
                    Next
                    <ArrowRightIcon />
                </Button>
            )}
        </OnboardingWrapper>
    );
};
