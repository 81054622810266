import React, { useState } from "react";
import { AudienceHeaderCTAProps } from "@/pages/AudienceNew/AudienceHeaderCTA";
import { FeatureFlagsEnum, useFeatureFlag } from "./useFeatureFlag";
import { useAudienceSizeValidations } from "./useAudienceSizeValidations";
import { useAudienceEstimate } from "./useAudienceEstimate";

export function useAudienceHeaderManagement({
    loading,
    audienceId,
    audience,
    isLoadingEstimateInfo,
    criteriaAssets,
}: AudienceHeaderCTAProps) {
    const isSummaryEnabled = useFeatureFlag(FeatureFlagsEnum.SUMMARY);
    const isPreviewEnabled = useFeatureFlag(FeatureFlagsEnum.PREVIEW);
    const isSplitView = isSummaryEnabled || isPreviewEnabled;

    const [disabledTooltip, setDisabledTooltip] = useState<string>();
    const {
        destinationsNotMeetingMinimumRequirements: destinationsNotMeetingMinimumRequirementsv1,
        audienceTooBig: audienceTooBigv1,
    } = useAudienceEstimate({ audience });
    const {
        destinationsNotMeetingMinimumRequirements: destinationsNotMeetingMinimumRequirementsv2,
        audienceTooBig: audienceTooBigv2,
    } = useAudienceSizeValidations({ audience, criteriaAssets, isCriteriaAssetsLoading: isLoadingEstimateInfo });

    const destinationsNotMeetingMinimumRequirements: string[] = React.useMemo(
        () => (isSplitView ? destinationsNotMeetingMinimumRequirementsv2 : destinationsNotMeetingMinimumRequirementsv1),
        [destinationsNotMeetingMinimumRequirementsv1, destinationsNotMeetingMinimumRequirementsv2, isSplitView],
    );
    const audienceTooBig: boolean = React.useMemo(
        () => (isSplitView ? audienceTooBigv2 : audienceTooBigv1),
        [isSplitView, audienceTooBigv1, audienceTooBigv2],
    );

    const isFinishDisabled = React.useMemo(() => {
        return (
            loading ||
            !audienceId ||
            !audience?.destinations ||
            isLoadingEstimateInfo ||
            destinationsNotMeetingMinimumRequirements.length > 0 ||
            Object.values(audience.destinations).every(a => !a) ||
            !audience.shape?.source_criteria?.group?.filters?.length ||
            audience.shape.source_criteria.group.filters.some(f => f.isDuplicated || f.isEmpty || f.customError)
        );
    }, [audienceId, audience, loading, isLoadingEstimateInfo, destinationsNotMeetingMinimumRequirements]);

    React.useEffect(() => {
        if (
            !audience?.destinations ||
            Object.values(audience.destinations).every(a => !a) ||
            !audience.shape?.source_criteria?.group?.filters?.length
        ) {
            setDisabledTooltip("Select a destination and add at least one filter to run the audience.");
        } else if (isLoadingEstimateInfo) {
            setDisabledTooltip("Estimating your audience, please wait.");
        } else if (
            audience.shape.source_criteria.group.filters.some(f => f.isDuplicated || f.isEmpty || f.customError)
        ) {
            setDisabledTooltip("One or more audience filters are invalid.");
        } else if (destinationsNotMeetingMinimumRequirements.length > 0) {
            setDisabledTooltip("Your audience is too small for at least one of your destinations.");
        }
    }, [audienceId, audience, loading, isLoadingEstimateInfo, destinationsNotMeetingMinimumRequirements]);

    return {
        isFinishDisabled,
        audienceTooBig,
        disabledTooltip,
    };
}
