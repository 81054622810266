import { useState } from "react";
import { useGetSubscriptionProductsQuery, useSubscriptionQuery } from "@/api/users";
import { InfoBar } from "@/components/organisms/audiences/AudiencesInfoBar/InfoBar";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/atoms/Popover";
import { UpgradePlanModal } from "@/components/organisms/subscription/UpgradePlan/UpgradePlanModal";
import { UpgradePlanAction } from "@/components/organisms/audiences/AudiencesInfoBar/UpgradePlanAction";
import { ActiveAudiencesAction } from "@/components/organisms/audiences/AudiencesInfoBar/ActiveAudiencesAction";
import { ArchiveAudiencesAction } from "@/components/organisms/audiences/AudiencesInfoBar/ArchiveAudiencesAction";

export const AudiencesInfoBar = () => {
    const [open, setOpen] = useState(false);
    const close = () => setOpen(false);

    const [upgradePlanOpened, setUpgradePlanOpened] = useState(false);

    const { data: products } = useGetSubscriptionProductsQuery();
    const { data: subscription } = useSubscriptionQuery(undefined);

    return (
        <Popover defaultOpen={open} onOpenChange={setOpen}>
            <PopoverTrigger className="mr-10">
                <InfoBar open={open} />
            </PopoverTrigger>

            <PopoverContent
                align="end"
                onInteractOutside={() => setOpen(false)}
                className="bg-white rounded-lg shadow-dropdown w-[320px] text-14 text-ui-700 -mt-2"
            >
                <ActiveAudiencesAction onSuccess={close} />

                <UpgradePlanAction
                    openPlan={() => {
                        setUpgradePlanOpened(true);
                        close();
                    }}
                />

                <ArchiveAudiencesAction onSuccess={close} />
            </PopoverContent>

            {upgradePlanOpened && subscription && products && (
                <UpgradePlanModal
                    subscription={subscription}
                    subscriptionProducts={products}
                    closeModal={() => setUpgradePlanOpened(false)}
                />
            )}
        </Popover>
    );
};
