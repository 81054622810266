import { differenceBy, find, isEqual } from "lodash";

import { SourceCriteriaFilter } from "@/types/audience";
import { getFormattedDateForAudienceName } from "@/utils/date.ts";
import { FilterOperators } from "@primer/filters/types";

export function getNewAudienceName() {
    return `Audience - ${getFormattedDateForAudienceName(new Date())}`;
}

export function allowEmpty(operator?: FilterOperators) {
    return (
        !!operator &&
        [
            FilterOperators.IS_KNOWN,
            FilterOperators.IS_UNKNOWN,
            FilterOperators.IS_WITHIN,
            FilterOperators.EXCLUDE,
        ].includes(operator)
    );
}

const onlyChangedInvalidFilter = (before: SourceCriteriaFilter[], after: SourceCriteriaFilter[]): boolean => {
    return before.every(beforeItem => {
        const afterItem = find(after, { unique_id: beforeItem.unique_id });
        if (afterItem?.customError === undefined) delete afterItem?.customError;

        return (
            !!afterItem &&
            !isEqual(beforeItem, afterItem) &&
            (beforeItem.isEmpty || beforeItem.isDuplicated || beforeItem.customError) &&
            (afterItem.isEmpty || afterItem.isDuplicated || afterItem.customError)
        );
    });
};

export const checkIfEstimateWillRemaingSame = (
    before: SourceCriteriaFilter[],
    after: SourceCriteriaFilter[],
): boolean => {
    const afterLength = after.length;
    const beforeLength = before.length;

    // New filter
    if (afterLength > beforeLength) {
        const newItems = differenceBy(after, before, "unique_id");
        return newItems.every(newItem => newItem.isEmpty || newItem.isDuplicated || !!newItem.customError);
    }

    // Deleted invalid filter
    if (afterLength < beforeLength) {
        const difference = before.filter(
            beforeItem =>
                !after.some(currentFilter => currentFilter.unique_id === beforeItem.unique_id) &&
                (beforeItem.isEmpty || beforeItem.isDuplicated || !!beforeItem.customError),
        );

        return difference.length > 0;
    }

    if (after.some(filter => filter.values?.some(v => v.invalid === undefined))) return true;

    // The change was from invalid filter to still invalid filter
    const changedInvalidKeptInvalid = onlyChangedInvalidFilter(before, after);

    return changedInvalidKeptInvalid;
};
