import { Separator } from "@/components/atoms/Separator/Separator.tsx";
import { Card } from "@/components/atoms/Card/Card.tsx";
import { getFormattedDateAndTime } from "@/utils/date";
import { AudienceSize } from "@/components/molecules/AudienceSize/AudienceSize";
import { FilterEntityTypes } from "@primer/filters/types";
import { GetAudienceResponse } from "@/types/api";
import { useAudienceEstimate } from "@/hooks/useAudienceEstimate";
import { AudienceEstimateHeuristics } from "./AudienceEstimateHeuristics";
import { FeatureFlagsEnum, useFeatureFlag } from "@/hooks";
import { CrmMatchRates } from "@/types/audience";

interface AudienceEstimateCardProps {
    audience?: GetAudienceResponse;
    crmMatchRates?: CrmMatchRates;
    isLoadingNewEstimate?: boolean;
    setIsSummaryLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

export const AudienceEstimateCard = ({
    audience,
    crmMatchRates,
    isLoadingNewEstimate,
    setIsSummaryLoading,
}: AudienceEstimateCardProps) => {
    const { smallAudienceErrorMessage, estimates, showAudienceSize, shouldShowError, exceeded } = useAudienceEstimate({
        audience,
        isLoadingNewEstimate,
    });

    const estimateSummaryEnabled = useFeatureFlag(FeatureFlagsEnum.QUICK_SUMMARY);

    return (
        <Card className="w-[300px] lg:w-[320px]">
            <div className="text-lg font-normal text-ui-700 leading-7">
                {showAudienceSize ? "Audience Size" : "Audience Estimate"}
            </div>
            <div className="flex mt-6">
                <AudienceSize
                    entityType={FilterEntityTypes.COMPANY}
                    estimate={estimates[FilterEntityTypes.COMPANY]}
                    sizeWarning={showAudienceSize ? false : exceeded[FilterEntityTypes.COMPANY]}
                    className="flex-1 mr-16"
                    loading={isLoadingNewEstimate}
                    showAudienceSize={showAudienceSize}
                    id={audience?.id}
                />
                <AudienceSize
                    entityType={FilterEntityTypes.PERSON}
                    estimate={estimates[FilterEntityTypes.PERSON]}
                    sizeWarning={
                        showAudienceSize ? false : exceeded[FilterEntityTypes.PERSON] || !!smallAudienceErrorMessage
                    }
                    sizeError={showAudienceSize ? false : !!smallAudienceErrorMessage}
                    className="flex-1"
                    loading={isLoadingNewEstimate}
                    showAudienceSize={showAudienceSize}
                    crmMatchRates={crmMatchRates}
                    id={audience?.id}
                />
            </div>
            {shouldShowError && (exceeded[FilterEntityTypes.COMPANY] || exceeded[FilterEntityTypes.PERSON]) && (
                <p className="text-sm text-warning my-2">Audience sizes are limited to a maximum of 3 million records.</p>
            )}
            {shouldShowError && smallAudienceErrorMessage && (
                <p className="text-sm text-error my-2">{smallAudienceErrorMessage}</p>
            )}
            {estimateSummaryEnabled &&
                !!audience?.shape?.heuristics?.people_count &&
                audience.shape.heuristics.people_count > 0 && (
                    <>
                        <Separator className="my-6" />
                        <AudienceEstimateHeuristics
                            setIsSummaryLoading={setIsSummaryLoading}
                            audience={audience}
                            isLoadingEstimate={isLoadingNewEstimate}
                        />
                    </>
                )}
            {audience?.lastUpdatedAt && (
                <>
                    <Separator className="my-6" />
                    <p className="text-[10px] text-ui-300 uppercase">Last Updated At</p>
                    <p className="text-sm text-ui-900 mt-2">
                        {getFormattedDateAndTime(new Date(audience.lastUpdatedAt))}
                    </p>
                </>
            )}
        </Card>
    );
};
