/* eslint-disable react-hooks/exhaustive-deps */

import { useGetAudienceShapeHeuristicQuery } from "@/api/audiences";
import Skeleton from "@/components/atoms/Skeleton/Skeleton";
import { GetAudienceResponse } from "@/types/api";
import React, { ReactElement } from "react";
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuTrigger,
} from "@/components/atoms/DropdownMenu/DropdownMenu";
import { ChevronDownIcon } from "@radix-ui/react-icons";
import { parseFilterValueLabel } from "@/utils/string";
import { random } from "lodash";
import { getQuickSummaryPercentage, getSummaryPercentage } from "@/utils/number";
import { groupOthers } from "@/utils/heuristics";

interface AudienceEstimateCardProps {
    audience: GetAudienceResponse;
    isLoadingEstimate?: boolean;
    setIsSummaryLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

const selectItems = [
    { description: "Job Title", value: "Job Title" },
    { description: "Annual Revenue", value: "Annual Revenue" },
    { description: "Company Location", value: "Company Location" },
    { description: "Headcount", value: "Headcount" },
    { description: "Industry", value: "Industry" },
    { description: "Seniority Level", value: "Seniority" },
];

export const AudienceEstimateHeuristics = ({
    audience,
    isLoadingEstimate = false,
    setIsSummaryLoading,
}: AudienceEstimateCardProps) => {
    const [currentEstimate, setCurrentEstimate] = React.useState(audience?.shape?.heuristics?.heuristics);
    const [loadingSkeletons, setLoadingSkeletons] = React.useState<ReactElement[]>([]);
    const [selectedTop, setSelectedTop] = React.useState("Job Title");
    const [topOptions, setTopOptions] = React.useState(selectItems);

    const { data, isLoading, isFetching } = useGetAudienceShapeHeuristicQuery(
        {
            audienceId: audience?.id,
            shapeId: audience?.shape?.id,
        },
        {
            skip: !audience?.id || !audience?.shape?.id || !!audience?.shape?.heuristics?.heuristics,
        },
    );
    const isUpdating = isLoadingEstimate || isLoading || isFetching;

    const selectedTopSummary = React.useMemo(
        () => groupOthers(currentEstimate?.top?.find(t => t.name === selectedTop)),
        [currentEstimate?.top, selectedTop],
    );

    React.useEffect(() => updateCurrentEstimate(), [data]);
    React.useEffect(() => updateSelection(), [currentEstimate]);
    React.useEffect(() => updateLoadingSkeletons(), [isUpdating, selectedTopSummary]);
    React.useEffect(() => setIsSummaryLoading(isUpdating), [isUpdating]);

    const updateCurrentEstimate = () => {
        if (data) setCurrentEstimate(data?.heuristics);
    };

    const updateSelection = () => {
        if (currentEstimate?.top && currentEstimate.top.length > 0) {
            const jobTitle = currentEstimate?.top?.some(t => t.name === "Job Title");
            if (!jobTitle) {
                setTopOptions(prev => prev.filter(p => p.description !== "Job Title"));
                setSelectedTop("Annual Revenue");
            }
        }
    };

    const updateLoadingSkeletons = () => {
        const skeletonElements: ReactElement[] = [];
        const count = Math.floor(Math.random() * 6) + 3;

        for (let i = 0; i < count; i++) {
            const widthName = random(15, 28) * 5;
            const widthPercentage = random(8, 10) * 5;

            skeletonElements.push(
                <div className="flex mt-3" key={i}>
                    <Skeleton className={`h-5 rounded-xl bg-gray-300`} style={{ width: `${widthName}px` }} />
                    <Skeleton
                        className={`h-5 rounded-xl ml-auto bg-gray-300`}
                        style={{ width: `${widthPercentage}px` }}
                    />
                </div>,
            );
        }

        setLoadingSkeletons(skeletonElements);
    };

    return (
        <div className="mt-5">
            <div className="text-lg font-normal text-ui-700 leading-7 mb-3">Quick Summary</div>
            <DropdownMenu>
                <DropdownMenuTrigger data-ph-capture-attribute-test-summ={selectedTop} asChild>
                    <div
                        data-ph-capture-attribute-test-summ={selectedTop}
                        className="flex items-center p-2 border border-ui-300/[0.32] group rounded-md h-[36px] cursor-pointer aria-expanded:outline aria-expanded:outline-2 aria-expanded:outline-blue-300 aria-expanded:border-0"
                    >
                        <span className="text-14 text-ui-700">{selectedTop}</span>
                        <ChevronDownIcon className="ml-auto transition-transform duration-200 group-aria-expanded:rotate-180" />
                    </div>
                </DropdownMenuTrigger>
                <DropdownMenuContent
                    align="start"
                    className="bg-white border-0 data-[side=top]:shadow-dropdown-t data-[side=bottom]:shadow-dropdown-b w-[var(--radix-dropdown-menu-trigger-width)]"
                >
                    {topOptions.map(item => (
                        <DropdownMenuItem
                            key={item.value}
                            aria-selected={item.value === selectedTop}
                            className="hover:bg-blue-50 px-4 py-1 hover:text-blue-800 aria-selected:text-blue-800 text-14"
                            onSelect={() => setSelectedTop(item.value)}
                        >
                            <label>{item.description}</label>
                        </DropdownMenuItem>
                    ))}
                </DropdownMenuContent>
            </DropdownMenu>
            {isUpdating && <div className="ml-1">{loadingSkeletons}</div>}
            {!isUpdating && selectedTopSummary && (
                <div className="text-ui-700 text-sm ml-1">
                    {selectedTopSummary.values.map(top => (
                        <div className="flex mt-3" key={top.key}>
                            <span className="mr-1">
                                {parseFilterValueLabel(
                                    String(top.label).toLowerCase(),
                                    top.label,
                                    selectedTop.toLowerCase(),
                                )}
                            </span>
                            <span className="ml-auto">{selectedTopSummary?.total
                                ? getQuickSummaryPercentage(selectedTopSummary, top.value)
                                : getSummaryPercentage(top.value, selectedTopSummary, audience?.shape?.heuristics)}</span>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};
