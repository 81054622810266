import { isEmpty } from "lodash";

import { Filter, FilterEntityTypes, SourceCriteria } from "@primer/filters/types";

import { useGetFirstPartyFiltersQuery } from "@/api/filters";
import { useGetCrmSyncQuery } from "@/api/crm";
import InfoCircledIcon from "@/assets/icons/info.svg?react";
import { Card } from "@/components/atoms/Card/Card.tsx";
import CircularProgressBar from "@/components/atoms/CircularProgressBar/CircularProgressBar";
import { Tooltip } from "@/components/atoms/Tooltip/Tooltip";
import { FilterCriteriaBuilder } from "@/components/organisms/FilterCriteriaBuilder/FilterCriteriaBuilder";
import { useFilterManagement } from "@/hooks/useFilterManagement";
import { useGetConnectionsHash } from "@/hooks/useGetConnectionsHash";
import { AudienceStatus } from "@/types/audience";
import { hasFirstPartyFilters, mapById } from "@/utils/filter";
import SFDCConnectionWarningMessage from "./SFDCConnectionWarning";
import { CriteriaError } from "@/hooks/useAudienceFilterValidation";
import CriteriaErrorBanner from "./CriteriaError";
import * as Collapsible from '@radix-ui/react-collapsible';
import LightCaret from "@/assets/icons/light-caret.svg?react";
import { FeatureFlagsEnum, useFeatureFlag } from "@/hooks";
import cn from "classnames";
import { useState } from "react";

interface CriteriaCardProps {
    saveCriteria: (filters: Filter[]) => void;
    audienceType: FilterEntityTypes;
    sourceCriteria?: SourceCriteria;
    status?: AudienceStatus;
    showCriteriaError: boolean;
    criteriaError?: CriteriaError;
}

export const CriteriaCard = ({
    saveCriteria,
    audienceType,
    sourceCriteria,
    status = AudienceStatus.DRAFT,
    showCriteriaError = false,
    criteriaError
}: CriteriaCardProps) => {
    const isSummaryEnabled = useFeatureFlag(FeatureFlagsEnum.SUMMARY);
    const isPreviewEnabled = useFeatureFlag(FeatureFlagsEnum.PREVIEW);
    const isSplitView = isSummaryEnabled || isPreviewEnabled;

    const { disabledFilters, filters, editableField, updateEditableField, saveFilter, removeFilter } =
        useFilterManagement(saveCriteria, sourceCriteria, status);
    const [open, setOpen] = useState(true);
    const handleOpenChange = (open: boolean) => {
        setOpen(open || !isSplitView);
    }

    const [connectionsHash] = useGetConnectionsHash();
    const { data: firstPartyFilters, isLoading: firstPartyFiltersLoading } = useGetFirstPartyFiltersQuery(
        { instanceId: connectionsHash.salesforce?.provider_account_id ?? "" },
        {
            skip: !connectionsHash.salesforce?.provider_account_id,
        },
    );
    const { data: crmSync } = useGetCrmSyncQuery(
        { instanceId: connectionsHash.salesforce?.provider_account_id ?? "" },
        {
            skip: !connectionsHash.salesforce?.provider_account_id,
        },
    );
    const mappedFirstPartyFilters = mapById(firstPartyFilters!);
    const showLoading = hasFirstPartyFilters(filters) && firstPartyFiltersLoading;

    return (
        <Card className="flex flex-col h-full mb-3 !p-[24px]">
            <Collapsible.Root open={open} onOpenChange={handleOpenChange} className="w-full mb-4">
                <Collapsible.Trigger asChild>
                    <div
                        role={isSplitView ? "button" : "tab"}
                        className={cn("flex gap-2 items-center text-ui-700 mb-2", {
                            "cursor-pointer": isSplitView
                        })}>
                        {isSplitView &&
                            <LightCaret
                                className={cn("transition-transform duration-500 ease-in-out w-6 h-6", { "rotate-180": open })}
                            />}
                        <span className="text-lg font-normal text-ui-700 mr-2">Criteria</span>
                        <Tooltip content="Results will match ALL of the filter criteria">
                            <InfoCircledIcon className="text-ui-300/[0.5]" />
                        </Tooltip>
                        {!open && isSplitView &&
                            <div className="ml-auto text-ui-400">
                                {sourceCriteria?.group?.filters?.length ?? 0}
                            </div>}
                    </div>
                </Collapsible.Trigger>
                <Collapsible.Content className="flex flex-col gap-1 pt-2">
                    {showCriteriaError && <div className="flex flex-row items-center">
                        <CriteriaErrorBanner criteriaError={criteriaError} />
                    </div>}
                    {!isEmpty(crmSync) && connectionsHash?.salesforce?.state === "failed" && (
                        <SFDCConnectionWarningMessage
                            lastSyncedAt={crmSync?.latestSyncJobSynchronizedAt || crmSync?.latestSyncJobCreatedAt}
                        />
                    )}
                    <div>
                        {showLoading ? (
                            <CircularProgressBar className="m-auto w-10 h-10" />
                        ) : (
                            filters.map((filter, index) => {
                                return (
                                    <FilterCriteriaBuilder
                                        index={index}
                                        key={filter.unique_id}
                                        sourceCriteria={sourceCriteria}
                                        audienceType={audienceType}
                                        filter={filter}
                                        disabled={disabledFilters}
                                        updateEditableField={updateEditableField}
                                        isEditable={editableField === filter.unique_id}
                                        saveFilter={saveFilter}
                                        mappedFirstPartyFilters={mappedFirstPartyFilters}
                                        removeFilter={removeFilter}
                                    />
                                );
                            })
                        )}
                    </div>
                </Collapsible.Content>
            </Collapsible.Root>
        </Card>
    );
};
