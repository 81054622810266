/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { minBy } from "lodash";
import { GetAudienceResponse } from "@/types/api";
import { FilterEntityTypes } from "@primer/filters/types";
import { AudienceShapeHeuristicsSchema, AudienceStatus, Destination } from "@/types/audience";
import { DESTINATIONS_MIN, MAX_COMPANIES, MAX_PEOPLE } from "@/constants/estimates";
import { DESTINATIONS } from "@/config/audience";
import { formatNumber } from "@/utils/number";

export const useAudienceSizeValidations = ({
    audience,
    criteriaAssets,
    isCriteriaAssetsLoading = false,
}: {
    audience?: GetAudienceResponse;
    criteriaAssets?: AudienceShapeHeuristicsSchema;
    isCriteriaAssetsLoading?: boolean;
}) => {
    const [errorMessageUpdated, setErrorMessageUpdated] = React.useState(false);
    const [smallAudienceErrorMessage, setSmallAudienceErrorMessage] = React.useState<string>();

    const showAudienceSize =
        !!audience?.isAudienceRunForLatestAudienceShape &&
        ![AudienceStatus.DRAFT, AudienceStatus.RUNNING].includes(audience?.status);

    const estimates = React.useMemo(
        () => ({
            [FilterEntityTypes.COMPANY]: showAudienceSize ? audience?.companies : criteriaAssets?.companies_count,
            [FilterEntityTypes.PERSON]: showAudienceSize ? audience?.people : criteriaAssets?.people_count,
        }),
        [showAudienceSize, audience, criteriaAssets],
    );

    const destinationsNotMeetingMinimumRequirements = React.useMemo(() => {
        if (!audience?.destinations) return [];

        const peopleEstimate = estimates[FilterEntityTypes.PERSON] ?? 0;
        const selectedDestinations = Object.keys(audience.destinations).filter(
            key => audience.destinations[key as Destination],
        );
        const smallDestinations = selectedDestinations.filter(
            destination => peopleEstimate < DESTINATIONS_MIN[destination as Destination],
        );

        return smallDestinations;
    }, [audience, estimates, isCriteriaAssetsLoading]);

    const exceeded = {
        [FilterEntityTypes.COMPANY]:
            !!estimates[FilterEntityTypes.COMPANY] && estimates[FilterEntityTypes.COMPANY] > MAX_COMPANIES,
        [FilterEntityTypes.PERSON]:
            !!estimates[FilterEntityTypes.PERSON] && estimates[FilterEntityTypes.PERSON] > MAX_PEOPLE,
    };
    const audienceTooBig = Object.values(exceeded).some(Boolean);

    React.useEffect(() => {
        if (isCriteriaAssetsLoading) {
            setSmallAudienceErrorMessage(undefined);
            setErrorMessageUpdated(false);
        } else {
            if (!destinationsNotMeetingMinimumRequirements.length) {
                setErrorMessageUpdated(true);
                setSmallAudienceErrorMessage(undefined);
                return;
            }

            const smallDestinations = destinationsNotMeetingMinimumRequirements.map(destination => ({
                destination,
                minSize: DESTINATIONS_MIN[destination as Destination],
            }));
            if (smallDestinations.length === 0) {
                setErrorMessageUpdated(true);
                setSmallAudienceErrorMessage(undefined);
                return;
            }

            const minimumSize = minBy(smallDestinations, "minSize")?.minSize;

            const destinations = smallDestinations
                .filter(s => s.minSize === minimumSize)
                .map(d => DESTINATIONS[d.destination as Destination]?.name);

            setErrorMessageUpdated(true);
            setSmallAudienceErrorMessage(
                `Recommended audience size for ${destinations.join(", ")} is >${formatNumber(minimumSize, [999, 9999], "")} records. Adjust your filters.`,
            );
        }
    }, [destinationsNotMeetingMinimumRequirements, isCriteriaAssetsLoading]);

    const shouldShowError = React.useMemo(() => {
        return !isCriteriaAssetsLoading && !!criteriaAssets && errorMessageUpdated;
    }, [isCriteriaAssetsLoading, criteriaAssets, errorMessageUpdated]);

    return {
        destinationsNotMeetingMinimumRequirements,
        estimates,
        smallAudienceErrorMessage,
        showAudienceSize,
        shouldShowError,
        exceeded,
        audienceTooBig,
    };
};
