import { useCallback, useEffect } from "react";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";

import { Modal } from "@/components/organisms/Modal";
import { ModalHeader } from "@/components/organisms/Modal/ModalHeader";
import Pagination from "@/components/molecules/Pagination/Pagination";
import { RunHeader } from "@/components/organisms/audiences/RunsModal/RunHeader";
import { RunRow } from "@/components/organisms/audiences/RunsModal/RunRow";
import { toast } from "@/components/atoms/Toast/useToast";
import { usePagination } from "@/hooks";
import { useGetAudienceRunsQuery, useRerunAudienceMutation } from "@/api/audiences";
import { AudienceStatus } from "@/types/audience";

type Props = {
    audienceId: string;
    audienceName: string;
    audienceStatus?: AudienceStatus;
    disabled?: boolean;
    onClose: () => void;
};

export function AudienceRunsModal({ audienceId, audienceName, audienceStatus, onClose, disabled = false }: Props) {
    const pagination = usePagination({ limit: 2 });
    const { updateTotal, from, limit, currentPage } = pagination;

    const {
        data,
        isLoading,
        refetch: refetchAudienceRuns,
        error: getAudienceRunsError,
    } = useGetAudienceRunsQuery(
        { audienceId, limit, offset: from },
        {
            pollingInterval: 1000 * 30,
            skipPollingIfUnfocused: true,
            refetchOnReconnect: true,
            refetchOnMountOrArgChange: true,
            refetchOnFocus: true,
        },
    );

    const [rerunAudienceMutation, { error: rerunAudienceError, isLoading: isRerunning }] = useRerunAudienceMutation();

    const handleRerun = useCallback(
        async (runId: string) => {
            await rerunAudienceMutation({ audienceId, runId });
            refetchAudienceRuns();
        },
        [audienceId, refetchAudienceRuns, rerunAudienceMutation],
    );

    useEffect(() => {
        // Refetch when modal is opened or when audience status is changed
        refetchAudienceRuns();
    }, [refetchAudienceRuns, audienceStatus]);

    useEffect(() => {
        updateTotal(data?.totalCount || 0, isLoading);
    }, [data?.totalCount, isLoading, updateTotal]);

    useEffect(() => {
        if (!getAudienceRunsError) return;

        const fetchBaseQueryError = getAudienceRunsError as FetchBaseQueryError;

        toast({ description: fetchBaseQueryError.data as string, variant: "destructive" });
    }, [getAudienceRunsError]);

    useEffect(() => {
        if (!rerunAudienceError) return;
        const fetchBaseQueryError = rerunAudienceError as FetchBaseQueryError;

        toast({ description: fetchBaseQueryError.data as string, variant: "destructive" });
    }, [rerunAudienceError]);

    return (
        <Modal className="w-[1200px]" onClose={onClose}>
            <ModalHeader title="Audience Runs" onClose={onClose} />
            <div className="grid grid-cols-[200px_170px_120px_auto_180px] text-ui-900 text-14 max-h-[700px] overflow-auto overflow-x-hidden">
                <RunHeader />
                {data?.data.map((audienceRun, index) => (
                    <RunRow
                        audienceName={audienceName}
                        audienceRun={audienceRun}
                        key={audienceRun.id}
                        isLatest={index === 0 && currentPage === 0}
                        handleRerun={handleRerun}
                        isRerunning={isRerunning}
                        disabled={disabled}
                    />
                ))}
            </div>
            <div className="flex justify-between items-center mt-5">
                <div>
                    <Pagination {...pagination} />
                </div>
            </div>
        </Modal>
    );
}
